input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
}
button {
  color: white;
  border: 3px solid $teal;
  background-color: $teal;
  padding: 20px $standard-padding * 2;
  letter-spacing: 2px;
  transition: 0.5s;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  font-family: 'Proxima Nova';
  span {
    color: $white;
  }
  &:hover {
    color: $white;
    background-color: $teal-button-hover;
    border: 3px solid $teal-button-hover;
    span {
      color: $teal;
    }
  }
  &:focus {
    color: $white;
    background: $teal-button-focus;
    border: 2px solid $teal-button-focus;
    span {
      color: $teal;
    }
  }
}

a {
  &.underline {
    border-bottom: $underline-width solid $link-teal;
    &:hover {
      background: $link-teal;
    }
  }
  &.teal-button {
    background-color: $teal;
    color: $white;
    padding: calc($standard-padding/1.5) $standard-padding * 2;
    &:hover {
      background-color: $teal-button-hover;
    }
    &:focus {
      background: $teal-button-focus;
    }
  }
}

button.dash-button {
  width: 100%;
  height: 48px;
  background: #e86431;
  border-color: #e86431;
  padding: 0;
  text-transform: uppercase;
  font-size: 14pt;
  font-weight: 700;
  a {
    color: white;
  }
}

button.card-button {
  width: 100%;
  height: 48px;
  background: #e86431;
  margin: 0;
  border-color: #e86431;
  padding: 0;
  text-transform: uppercase;
  font-size: 14pt;
  font-weight: 700;
  a {
    color: white;
  }
}

#search-submit{
  font-size: 14px;
}