@keyframes fadein {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

.filter-container{
    position: relative;
    margin-right: 20px;
    @media(max-width: 720px){
      width: 100% !important;
    }
    .filter{
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 36px;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      color: #818181;
      background-color: #EBEBEB;
      border: 1px solid transparent;
      border-radius: 9999px;
      font-size: 16px;
      cursor: pointer;
      box-shadow:  0 0 0 -3px rgba(34, 34, 34, 0.2), 0 0 0 -2px rgba(34, 34, 34, 0.05);
      transition: border-color 300ms ease-in-out, box-shadow 300ms ease-in-out;
      &:hover {
        border: 1px solid #007D7F;
      }
      &.active{
        border: 1px solid #007D7F;
        box-shadow:  0 10px 15px -3px rgba(34, 34, 34, 0.2), 0px 4px 6px -2px rgba(34, 34, 34, 0.05);
      }
      &.selected{
        color: white;
        background-color: #007D7F;
      }
      .label{
        text-transform: capitalize;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
      }
      .right-side{
        display: flex;
        align-items: center;
        margin-left: auto;
        .num-of-selected-filter-options{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 16px;
          padding: 5px;
          color: #007D7F;
          background-color: white;
          font-size: 14px;
          font-weight: 700;
          border-radius: 9999px;
        }
        .icon{
          margin-left: 20px;
          font-size: 20px;
        }
      }
    }
    .mobile-label{
      margin-top: 40px;
      color: black;
      font-size: 20px;
      font-weight: 700;
      font-family: "Proxima Nova Bold", sans-serif;
      text-transform: capitalize;
    }
    .filter-options{
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      top: 40px;
      left: 0;
      max-height: 300px;
      overflow-y: scroll;
      padding: 20px 35px 10px 20px;
      background-color: #EBEBEB;
      border-radius: 8px;
      box-shadow:  0 10px 15px -3px rgba(34, 34, 34, 0.2), 0px 4px 6px -2px rgba(34, 34, 34, 0.05);
      z-index: 100;
      animation: fadein 300ms ease-in-out;
      @media(max-width: 720px){
        position: relative;
        top: 10px;
        width: auto;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
      }
      .input-container{
        position: relative;
        width: calc(100% - 10px);
        margin-bottom: 20px;
        @media(max-width: 720px){
          width: 100%;
          margin-bottom: 12px;
        }
        input{
          height: 48px;
          padding: 16px;
          padding-right: 35px;
          background-color: white;
          border: 1px solid #000000;
          border-radius: 8px;
          &::placeholder{
            color: #818181;
            letter-spacing: 1px;
          }
          &:focus{
            border-color: #E86431;
          }
        }
        .icon{
          position: absolute;
          right: 16px;
          color: #818181;
        }
      }
      .filter-options.hidden-options {
        animation: fadout 300ms ease-in-out;
      }
      .filter-option{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        margin-right: 8px;
        height: 40px;
        padding: 5px 10px;
        color: #007D7F;
        font-size: 16px;
        border: 1px solid #007D7F;
        border-radius: 9999px;
        white-space: pre;
        cursor: pointer;
        @media(max-width: 720px){
          width: auto;
        }
        &.selected{
          color: white;
          background-color: #007D7F;
        }
        .add-icon, .remove-icon{
          margin-left: 5px;
          font-size: 22px;
        }
      }
      .close-icon-container{
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        @media(max-width: 720px){
          display: none;
        }
        .close-icon:last-child{
          color: #007D7F;
          font-size: 22px;
        }
      }
    }
  }
