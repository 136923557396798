.Select {
  height: 48px;
  padding: 16px;
  background: #FFFFFF;
  font-family: 'Arial';
  font-size: 14px;
  border: 1px solid #000000;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 0;
  max-width: 415.5px;
  background: white url('data:image/svg+xml;utf8,<svg viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg"><path fill="%23000" d="M35 45l35 35 35-35z"/></svg>') no-repeat;
  background-position: right 10px center;
  background-size: 20px 20px;

  &:focus {
    border: 1px solid #E86431;
    outline: none;
  }

  &__label {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    margin-top: 24px;
  }

  &--error {
    border-color: #ED254E !important;
  }
}

.Select__error {
  color: #ED254E;
  font-size: 14px;
  line-height: 16px;
}
