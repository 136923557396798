.checkbox-label-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 4px;

  .checkbox-container {
    display: inline-block;
    vertical-align: middle;
    border: 2px solid #007d7f;
    width: 28px;
    height: 28px;
    border-radius: 8px;
  }

  .hidden-checkbox {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .styled-checkbox {
    display: inline-flex;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 8px;
    transition: all 150ms;
    margin-left: -2px;
    margin-top: -2px;
    box-shadow: 0 0 0 0 rgba(0, 125, 127, 0);
  }

  &:hover {
    .styled-checkbox {
      box-shadow: 0 0 0 3px rgba(0, 125, 127, 0.4);
    }
  }

  .check-img {
    height: 16px;
    margin-top: 5px;
  }

  .checkbox-label {
    text-transform: none;
    letter-spacing: normal;
    font-size: 20px;
    line-height: 24px;
    margin-left: 14px;
  }
}
