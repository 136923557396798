#resources-section{
    position: relative;
    padding: 25px;
    background-color: white;
    .resources-search{
      z-index: 100;
      @media(max-width: 720px){
        margin-top: 15px;
      }
    }
    .resources-content{
      display: flex;
      position: relative;
      margin-top: 35px;
      z-index: 50;
      @media(max-width: 930px){
        display: block;
      }
      @media(max-width: 720px){
        margin-top: 25px;
      }
      .search-results-container{
        width: 100%;
        .search-results{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          @media(max-width: 1050px){
            width: 100%;
          }
          .resource-container{
            width: calc(50% - 12px);
            margin-bottom: 37px;
            @media(max-width: 640px){
              width: 100%;
            }
          }
          .pagination{
            display: flex;
            position: relative;
            top: 5px;
            margin-bottom: 5px;
            @media(max-width: 930px){
              justify-content: center;
            }
            .icon-container{
              cursor: pointer;
              .pagination-icon{
                color: #007D7F;
                font-size: 30px;
              }
            }
            .page-range{
              position: relative;
              top: 3px;
              margin-left: 25px;
              margin-right: 25px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  