.location-search{
  .input-container-inner{
    position: relative;
  }
  .selected-locations{
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    max-height: 120px;
    overflow-y: scroll;
    @media (min-width: 1189px) {
      max-height: 170px;
    }

    .selected-location{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      margin-right: 8px;
      height: 40px;
      padding: 5px 10px;
      color: white;
      background-color: #007D7F;
      font-size: 16px;
      border: 1px solid #007D7F;
      border-radius: 9999px;
      white-space: pre;
      cursor: pointer;
      .remove-icon{
        margin-left: 5px;
        font-size: 22px;
      }
    }
  }
}
.pac-container{
  box-shadow: 0 10px 15px -3px rgba(34, 34, 34, 0.2), 0px 4px 6px -2px rgba(34, 34, 34, 0.05);
  &:after{
    height: 0;
    margin: 0;
    padding: 0;
    background-image: none !important;
  }
  .pac-item{
    padding: 8px 15px;
    border: none !important;
    cursor: pointer;
    span{
      color: black !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      font-family: "Proxima Nova", sans-serif !important;
    }
    .pac-icon{
      display: none;
    }
    .pac-item-query:after{
      content: ",";
    }
  }
}
