#favorites-section{
    position: relative;
    padding: 25px;
    background-color: white;
    .fav-title{
      padding-bottom: 0;
      color: #007D7F;
      font-size: 40px;
      font-weight: 900;
      font-family: "Proxima Nova Bold Italic", sans-serif !important;
      line-height: 60px;
      margin: 0;
      @media(max-width: 950px){
        font-size: 34px;
        font-weight: 700;
        line-height: 36px;
      }
    }
    .favorites-content{
      display: flex;
      position: relative;
      margin-top: 25px;
      z-index: 50;
      flex-wrap: wrap;
      gap: 20px;
      @media(max-width: 420px){
        margin-top: 40px;
      }
      .no-favorites-wrapper{
        width: 100%;
        height: 60vh;
        justify-content: center;
        align-items: center;
        display: flex;
        @media(max-width: 420px){
          align-items: flex-start;
        }
        .content-wrapper{
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: auto
        }
        .message{
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          color: #818181;
          width: 361px;
          @media(max-width: 420px){
            width: 100%;
            font-size: 20px;
          }
        }
        .dash-button{
          width: 263px;
          height: 48px;
          background: #E86431;
          margin: 32px 0px;
          border-color: #e86431;
          padding: 0;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 700;
          a{
            color: white
          }
        }
      }
    }
  }