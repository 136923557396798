.Input {
	height: 48px;
	padding: 16px;
	background: #FFFFFF;
	box-sizing: border-box;
	font-size: 14px;
	border: 1px solid #000000;
	&:focus{
		border: 1px solid #E86431;
	}
	&__label {
		font-family: 'Proxima Nova';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		text-transform: none;
		margin-top: 24px;
	}
	&--error {
		border-color: #ED254E !important;
	}
	&__error {
		color: #ED254E;
		font-size: 14px;
		line-height: 16px;
	}
}


.TextAreaContainer {
	display: flex;
	flex-direction: column;
	@media(max-width: 930px){
		width: 100%;
	}
	width: 415.5px;
	height: 246px;
	border: 1px solid #000000;
	&:focus-within{
		border: 1px solid #E86431;
	}
	&--error {
		border-color: #ED254E !important;
	}

	&__maxLength {
		height: 48px;
		background-color: white;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		z-index: 300;
		bottom: 2px;
		right: 4px;
		left: 4px;
		padding-right: 16px;

		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: #818181;
	}
}
.TextArea {
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #818181;

	width: 100%;
	height: 100%;
	background: #FFFFFF;
	border: none !important;
}
