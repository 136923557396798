.onboarding-overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.modal-container{
  background-color: white;
  width: 938px;
  height: 636px;
  position: fixed;
  margin-top: 8vh;
  @media(max-width: 990px) {
    width: 90%;
    height: auto;
    margin-top: 15vh;
  }
  @media(max-width: 1025px) and (min-width:990px) {
    margin-top: 13vh;
  }
  @media(max-width: 1400px) and (min-width: 1025px) {
    margin-top: 8vh;
  }
  @media(min-width: 1400px) {
    margin-top: 15vh;
  }

  .welcome-header{
    font-family: 'Proxima Nova Bold' !important;
    font-style: italic;
    font-weight: 900;
    font-size: 40px;
    line-height: 60px;
    color: #007D7F;
    margin-bottom: 20px;
    @media(max-width: 990px) {
      font-size: 34px;
      line-height: 36px;
    }
  }
  .modal-text{
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    @media(max-width: 990px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  .modal-text2{
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    @media(max-width: 990px) {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 100px;
    }
  }

  .close-button{
    position: absolute;
    top: 28px;
    right: 28px;
  }

  .wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    @media(max-width: 990px) {
      padding: 28px 40px;
    }
  }

  .form-container{
    width: 550px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 86px;
    margin: auto;

    .new-input{
      height: 48px;
      padding: 16px;
      background: #FFFFFF;
      border: 1px solid #000000;
      box-sizing: border-box;
      font-size: 14px;
      &:focus{
        border: 1px solid #E86431;
      }
    }
    .input-label{
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-transform: none;
    }
  }
  .form-header{
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 36px;
    color: #007D7F;
  }
}
.modal-content{
  margin: 24px;
}
.confirm-buttons{
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
}
.fill-button{
  padding: 16px;
  background: #E86431;
  text-transform: uppercase;
  color: #FFFFFF;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 14pt;
  line-height: 16px;
  border: 3px solid #E86431;
  width: 49%;
  margin: 0;
  &:hover{
    background: #A13A12;
    border: 3px solid #A13A12;
  }
  &:focus {
    background: #A13A12;
    border: 3px solid #A13A12;
  }
}

button.outline-button, input[type=file].outline-button{
  font-family: 'Proxima Nova Bold';
  border: 2px solid #E86431;
  box-sizing: border-box;
  background-color: transparent;
  color: #E86431;
  padding: 16px;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  width: 49%;
  text-transform: uppercase;
  border-radius: 8px;
  &:hover{
    color: #A13A12;
    border: 2px solid #A13A12;
    background-color: transparent;
  }
}

.skip-button{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}
.skip-text{
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #007D7F;
  margin-right: 10px;
}

.progress-container{
  background: #EBEBEB;
  height: 10px;

  .progress{
    background: #007D7F;
    height: 100%;
  }
}

.step-container{
  margin-top: 8px;
}
.current-step{
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #818181;
}
.upload-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-img{
  height: 16px;
  margin-right: 16px;
}
.upload-button-text{
  margin: 0;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
.form-info-text{
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #818181;
  margin-top: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.checkgroup-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 30px;
}
.checkbox-container{
  display: inline-block;
  vertical-align: middle;
  border: 2px solid #007D7F;
  width: 28px;
  height: 28px;
  border-radius: 8px;
}
.hidden-checkbox{
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.styled-checkbox{
  display: inline-flex;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  transition: all 150ms;
  margin-left: -2px;
  margin-top: -2px;
}
.check-img{
  height: 16px;
  margin-top: 5px;
}
.checkbox-label{
  text-transform: none;
  letter-spacing: normal;
  font-size: 20px;
  line-height: 24px;
  margin-left: 14px;
}

.filter-option{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 8px;
  height: 40px;
  padding: 5px 10px;
  color: #007D7F;
  font-size: 16px;
  border: 1px solid #007D7F;
  border-radius: 9999px;
  white-space: pre;
  width: fit-content;
  cursor: pointer;
  @media(max-width: 720px){
    width: auto;
  }
  &.selected{
    color: white;
    background-color: #007D7F;
  }
  .add-icon, .remove-icon{
    margin-left: 5px;
    font-size: 22px;
    visibility: visible;
  }
}
.filter-option-container{
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 170px;
}

.dropdown{
  padding: 16px;
  max-width: 100%;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  background-image: url('../images/down-arrow.png');
  background-repeat: no-repeat;
  background-position: 95% 50%;
  margin-bottom: 0;
  border-radius: 8px;
}
.dropdown-container{
  position: relative;
  cursor: pointer;
}
.dropdown-label {
  margin-bottom: 8px;
}

.drop-list{
  padding: 16px;
  position: absolute;
  width: 100%;
  max-height: 182px;
  overflow-y: auto;
  z-index: 120;
  background: #FFFFFF;
  box-shadow: 0px 10px 15px -3px rgba(34, 34, 34, 0.2), 0px 4px 6px -2px rgba(34, 34, 34, 0.05);
  border-radius: 16px;
}
.list-item{
  cursor: pointer;
  &:hover{
    color: #E86431;
  }
}
.dropdown-text{
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #818181;
  margin: 0;
}

.input-container{
  position: relative;
  width: 100%;
  margin-right: 20px;
  @media(max-width: 720px){
    margin-bottom: 15px;
    &:nth-child(1){
      width: calc(100% - 60px);
    }
    &:nth-child(3){
      width: 100%;
      margin-right: 0;
    }
  }
  input{
    height: 48px;
    padding: 16px;
    background-color: white;
    border: 1px solid #000000;
    &::placeholder{
      color: #818181;
    }
  }
  .icon{
    position: absolute;
    top: 15px;
    right: 18px;
    color: #818181;
  }
}

.community-list{
  display: flex;
  margin: 55px 0;
  flex-wrap: wrap;
  height: 100%;
  overflow-y: scroll;
}
