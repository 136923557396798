#events-section{
    position: relative;
    padding: 25px;
    background-color: white;
    .events-search{
      z-index: 100;
      @media(max-width: 720px){
        margin-top: 15px;
      }
    }
    .events-content{
      display: flex;
      position: relative;
      margin-top: 16px;
      z-index: 50;
      @media(max-width: 930px){
        display: block;
      }
      @media(max-width: 720px){
        margin-top: 25px;
      }
      .search-results{
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        padding: 0px 5px;
        height: 100%;
        width: 100%;
        @media(max-width: 930px){
          max-height: none;
          align-items: center;
        }
        @media screen and (min-width: 930px) {
          min-width: 335px;
        }
        .event-container{
          display: block;
          margin-bottom: 25px;
          @media(max-width: 960px){
            margin-right: 15px;
          }
          @media(max-width: 930px){
            display: inline-block;
            max-width: 370px;
            margin-right: -15px;
          }
          @media(max-width: 720px){
            margin-right: 0;
          }
          @media(max-width: 600px){
            width: 100%;
          }
          .event{
            @media(max-width: 930px){
              text-align: left;
            }
            @media(max-width: 720px){
              margin-right: 0;
            }
            @media(max-width: 600px){
              width: 100%;
            }
          }
        }
        .pagination{
          display: flex;
          position: relative;
          top: 5px;
          margin-bottom: 5px;
          @media(max-width: 930px){
            justify-content: center;
          }
          .icon-container{
            cursor: pointer;
            .pagination-icon{
              color: #007D7F;
              font-size: 30px;
            }
          }
          .page-range{
            position: relative;
            top: 3px;
            margin-left: 25px;
            margin-right: 25px;
            font-size: 20px;
          }
        }
      }
    }
  }
  