.OnboardingOverlay {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	z-index: 100000;
  &__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  &__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 20px;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
      background: #EBEBEB;
      border-radius: 9999px;
  }
  ::-webkit-scrollbar-track {
      background: transparent;
  }
}

.OnboardingModal {
  height: 500px;
  margin: auto;
	background-color: white;
	z-index: 250;
  width: 100%;
	max-width: 664px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1001px) {
    max-width: 800px;
    height: 664px;
  }
  &__ImageContainer {
    width: 100%;
    max-height: 256px;
    background-image: url(../../../../public/images/onboarding-photo-mobile.png);
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: fill;
    border-radius: 8px 8px 0 0;
    @media (min-width: 601px) {
      background-image: url("../../../../public/images/onboarding-photo-desktop.jpg");
    }
  }
  &__Content {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 640px;
    margin: auto;
    @media (min-width: 1001px) {
      padding: 48px 0;
      gap: 24px;
    }
    .input-label {
      letter-spacing: 0;
      margin: 0;
    }
    .new-input {
      border-radius: 8px;
    }
    overflow-y: scroll;
    &--no-overflow {
      overflow: hidden;
      flex: 1;
    }
    &--overflow-y {
      overflow-y: scroll;
      overflow-x: hidden;
      flex: 1;
    }
    &--overflow-unset {
      overflow: unset;
      flex: 1;
    }
    &--no-padding {
      padding: 0;
    }
  }
  &__Buttons {
    display: flex;
    gap: 16px;
    text-transform: uppercase;
    @media (min-width: 1001px) {
      margin-top: 40px;
    }
    .Button {
      flex: 1;
    }
  }
}
.current-step{
  all: unset;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #818181;
  display: block;
  @media (min-width: 1001px) {
    display: none;
  }
  &-bottom {
    visibility: hidden;
    @media (min-width: 1001px) {
      visibility: visible;
      display: block;
    }
  }
}
.checkgroup-container{
  margin: 0;
  display: flex;
  flex-direction: column;
  @media (min-width: 1001px) {
    flex-direction: row;
  }
}
.checkgroup {
  @media (min-width: 1001px) {
    width: 50%
  }
}

.filter-option-container {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: calc(100% - 48px - 16px); // Search box height + margin
  overflow-y: scroll;
  @media (min-width: 1001px) {
    flex-direction: row;
    flex-wrap: wrap;
    height: calc((64px) * 3);
    overflow: hidden;
    &--two-lines {
      height: calc((72px) * 2);
      overflow: scroll;
    }
  }
}
.community-list {
  margin-top: 16px;
  margin-bottom: 0;
  @media (min-width: 1001px) {
    margin-top: 24px;
  }
}

div.filter-option-modal {
  margin-bottom: 16px;
  margin-right: auto;
  @media (min-width: 1001px) {
    margin-right: 12px;
    margin-bottom: 24px;
  }
}

.FormWithSearchContainer {
  overflow: hidden;
  max-height: 236px;

  @media (min-width: 1001px) {
    max-height: auto;
  }
}
.input-container {
  margin-bottom: 16px;
  width: 100% !important;
  .input-container-inner {
    .location {
      font-size: 18px;
    }
  }
}

.no-scroll {
  overflow: hidden;
}

.modal-content {
  margin: 0;
}
.mobile-hidden {
  display: none;
  @media (min-width: 1001px) {
    display: block;
  }
}
.desktop-hidden {
  display: block;
  @media (min-width: 1001px) {
    display: none;
  }
}
.icon {
  position: absolute;
  right: 16px;
  top: 16px;
}
.radio-label {
  margin-top: 10px;
}