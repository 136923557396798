.Skeleton {
  display: flex;
  position: relative;
  background-color: #EBEBEB !important;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: auto !important;
  overflow: hidden;
  &--loading {
    z-index: 110;
    position: absolute;
    width: max(600px, 100%);
    height: 100%;
    background: linear-gradient(90deg, rgba(235, 235, 235, 0) 0.72%, #BDBCBC 46.97%, rgba(235, 235, 235, 0) 98.88%);
    animation: left-to-right 1.3s linear infinite;
  }
  &--rounded {
    border-radius: 24px;
  }
}

@keyframes left-to-right {
  0% {
    transform: translateX(-100%);
  }
  23% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
