.navbar-container{
  width: 110px;
  z-index: 10001;
  @media(max-width: 780px){
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  .navbar{
    position: fixed;
    height: 100%;
    max-height: 808.5px;
    width: 80px;
    text-align: center;
    color: black;
    background-color: white;
    border-radius: 8px;
    font-family: "Proxima Nova", sans-serif !important;
    box-shadow: 0 4px 6px -1px rgba(34, 34, 34, 0.2), 0 2px 4px -1px rgba(34, 34, 34, 0.06);
    overflow-y: auto;
    &:not(.logged-out){
      &.wide-view{
        .logo-container{
          display: block;
        }
      }
    }
    &.logged-out{
      @media(max-width: 780px){
        justify-content: space-between;
        &:not(.wide-view){
          .logged-out-buttons{
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            margin-top: 0;
            .sign-up-button, .login-button{
              height: 40px;
              margin-bottom: 0;
              padding: 0;
              letter-spacing: 0.5px;
            }
            .sign-up-button{
              width: 88px;
              margin-left: 12px;
            }
            .login-button{
              width: 78px;
            }
            .donate-button{
              display: none;
            }
          }
          .links-container{
            display: none
          }
        }
      }
      &.wide-view{
        .menu-buttons{
          display: block;
          @media(max-width: 780px){
            margin-top: 55px;
          }
        }
      }
      @media(max-width: 780px){
        padding-left: 15px;
        padding-right: 15px;
        .hamburger-icon{
          width: 35px;
          margin-left: 2px;
        }
      }
      .user-headshot-container{
        display: none !important;
      }
      .links-container{
        margin-top: 80px !important;
        @media(max-width: 780px){
          margin-top: 50px !important;
        }
        .dashboard, .favorites, .settings{
          display: none;
        }
      }
      .logged-out-mobile-logo-container{
        margin-top: 60px;
        text-align: center;
      }
    }
    &.wide-view{
      width: 282px;
      padding-left: 30px;
      padding-right: 30px;
      text-align: left;
    }
    @media(max-width: 780px){
      display: flex;
      position: relative;
      height: 64px;
      max-height: none;
      width: 100%;
      border-radius: 0;
      &.wide-view{
        display: block;
        position: fixed;
        height: 100%;
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        overflow-y: auto;
        .hamburger-icon{
          display: none;
        }
        .close-icon-container{
          display: block;
          position: absolute;
          top: 18px;
          right: 40px;
          .close-icon{
            color: #E86431;
          }
        }
      ;
      }
      .hamburger-icon-container{
        width: 12.5%;
        margin-top: 6px !important;
      }
    }
    .hamburger-icon-container{
      cursor: pointer;
      .hamburger-icon{
        margin-top: 20px;
      }
    }
    .close-icon-container{
      display: none;
      cursor: pointer;
    }
    .divider{
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      height: 2px;
      width: 100px;
      background-color: #EBEBEB;
      border-radius: 9999px;
      @media(max-width: 780px){
        margin-top: 20px;
      }
    }
    .user-headshot-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 75px;
      &.wide-view{
        flex-wrap: wrap;
        margin-top: -10px;
        text-align: center;
        @media(max-width: 780px){
          margin-top: 60px;
          flex-direction: column;
        }
        img{
          height: 70px;
          width: 70px;
          border-width: 5.6px;
        }
        .user-headshot-placeholder{
          height: 70px;
          width: 70px;
          font-size: 34px;
        }
      }
      img{
        height: 40px;
        width: 40px;
        border: 3.2px solid #FCED17;
        border-radius: 50%;
        object-fit: cover;
      }
      .user-headshot-placeholder{
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        height: 40px;
        width: 40px;
        color: black;
        background-color: #FCED17;
        font-size: 20px;
        font-family: "Proxima Nova Bold", sans-serif;
        border-radius: 9999px;
        line-height: 1;
        text-transform: uppercase;
      }
      .welcome-message{
        margin-top: 8px;
        font-family: "Proxima Nova Bold", sans-serif;
        font-size: 20px;
        font-weight: 700;
      }
      .datetime{
        margin-top: 4px;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .links-container{
      margin-top: 40px;
      margin-bottom: 20px;
      &.wide-view{
        margin-top: -10px;
      }
      @media(max-width: 780px){
        margin-top: -10px;
        &.wide-view{
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          margin-top: 45px;
        }
        &:not(.wide-view){
          display: flex;
          justify-content: space-around;
          width: 100%;
        }
      }
      .link-container{
        display: block;
        margin-top: 35px;
        color: black;
        cursor: pointer;
        &.wide-view{
          margin-top: 30px;
        }
        &.active{
          color: #007D7F;
          .label{
            font-family: "Proxima Nova Bold", sans-serif;
            font-weight: 700;
          }
        }
        .label{
          position: relative;
          top: -2px;
          margin-left: 20px;
          font-size: 20px;
          font-weight: 400;
        }
      }
      .link-container:hover{
        color: #007D7F;
        img {
          filter: invert(29%) sepia(79%) saturate(1027%) hue-rotate(145deg) brightness(102%) contrast(101%);
        }
      }
    }
    .logo-container{
      display: none;
      .logo{
        display: block;
        width: 151px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        @media(max-width: 780px){
          position: relative;
          top: 35px;
        }
      }
    }
    .logged-out-buttons, .menu-buttons{
      display: none;
      margin-top: 100px;
      button{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 100%;
        margin: 0 0 15px 0;
        font-size: 14px;
        font-family: "Proxima Nova Bold", sans-serif;
        &:focus-visible{
          outline: none;
        }
      }
      .sign-up-button{
        color: white;
        background-color: #E86431;
        border: none;
      }
      .donate-button, .login-button{
        color: #E86431;
        background-color: white;
        border: 2px solid #E86431;
      }
    }
  }
}
