@mixin filterStyles($color, $bgColor, $borderColor) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 8px;
  height: 40px;
  padding: 8px 16px;
  color: $color;
  font-size: 16px;
  background-color: $bgColor;
  border: 1px solid $borderColor;
  border-radius: 9999px;
  white-space: pre;
  cursor: pointer;
}
@mixin iconStyles($color, $size) {
  font-size: $size;
  color: $color;
}

.text-header {
  font-family: 'Proxima Nova Bold', sans-serif !important;
  color: #007d7f;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  &--margin {
    margin-top: 48px;
    margin-bottom: 16px;
  }
}
.text-answer {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-transform: capitalize;
}

.filter-option {
  @include filterStyles(#818181, #ebebeb, #ebebeb);
  @media (max-width: 720px) {
    width: auto;
  }
  &.selected {
    color: white;
    background-color: #007d7f;
    border-width: 2px;
    border-color: #007d7f;
  }
  .add-icon,
  .remove-icon {
    margin-left: 5px;
    font-size: 22px;
  }

  border-width: 2px;

  &:hover {
    border-width: 2px;
    border-color: #007d7f;
  }
  transition: border-color 300ms ease-in-out;
}

.filter-option-modal {
  @include filterStyles(#007d7f, #ffffff, #007d7f);
  @media (max-width: 720px) {
    width: auto;
  }
  &.selected {
    color: white;
    background-color: #007d7f;
  }
  .add-icon,
  .remove-icon {
    margin-left: 5px;
    font-size: 22px;
    visibility: inherit;
  }
}

.setting-info {
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #818181;
  @media (max-width: 860px) {
    display: none;
  }
}
.settings-header {
  display: flex;
}
.settings-pencil-icon {
  @include iconStyles(#e86431, 30px);
  margin-left: 14px;
}
.settings-logout-icon {
  @include iconStyles(#ed254e, 30px);
}

.settings-content {
  display: flex;
  z-index: 50;
  overflow-y: scroll;
  margin-bottom: 80px;
  margin-top: 64px;
}
.settings-content::-webkit-scrollbar {
  display: none;
}
.bottom-save-button {
  position: absolute;
  bottom: 28px;
  @media (max-width: 860px) {
    width: 90%;
  }
}

.question-block {
  width: 40%;
  @media (max-width: 860px) {
    width: 100%;
  }
}
.question {
  width: 100%;
}
.settings-wrapper {
  width: 90%;
  @media (max-width: 860px) {
    width: 100%;
  }
}
.flex-wrap {
  display: flex;
  @media (max-width: 860px) {
    gap: 20px
  }
}
