.HorizontalButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 52px;
  width: 100%;
  border: 2px solid #EBEBEB;
  padding: 0 12px;
  border-radius: 8px;
  background-color: transparent !important;
  color: #818181;
  @media (min-width: 1001px) {
    height: 72px;
  }

  &--active, &:hover {
    border: 2px solid #007D7F;
  }
  &__text {
    margin-left: 12px;
  }
  &__icon {
    height: 24px;
    width: 24px;
    margin-left: auto;
  }
  &:hover > &__icon, &--active > &__icon {
    color: #007D7F;
  }
}


.RadioButtons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 1001px) {
    gap: 24px;
  }
}