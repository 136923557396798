div.job {
  border-radius: 8px;
  width: 100%; 
  max-width: 560px;
  transition: all 100ms ease-out;
  &:hover, &:active, &.selected {
    outline: 4px solid #7FC3C4;
  }
  .header__image{
    border-top-left-radius: 8px;
  }
}

.job {
  display: inline-block;
  position: relative;
  max-width: inherit;
  min-width: 332px;
  padding: 20px;
  background-color: white;
  text-decoration: none;
  border-radius: 8px;
  z-index: 50;
  box-shadow: 0 10px 15px -3px rgba(34, 34, 34, 0.2),
  0px 14px 16px -2px rgba(34, 34, 34, 0.05);
  transition: box-shadow 300ms ease-in-out;

  .top-row {
    display: grid;
    align-items: center;
    grid-template-columns: 14% 67% 10%;
    gap: 0.75rem;
    height: 40px;

    .image {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }

    .middle-section {
      color: #818181;
      font-size: 14px;
      white-space: normal;
    }

    .favorite {
      margin-left: auto;
      color: #e86431;
      background-color: transparent;
      font-size: 21px;
    }
  }

  .title {
    height: 40px;
    margin-top: 12px;
    margin-bottom: 12px;
    color: black;
    font-family: 'Proxima Nova Bold', sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    white-space: pre-wrap;
    overflow: hidden;
  }

  .salary {
    height: 32px;
    margin-bottom: 12px;
    color: black;
    font-size: 14px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .job-type {
    height: 16px;
    color: #818181;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .level {
    height: 16px;
    color: #818181;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  .tags {
    height: 16px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    column-gap: 3px;

    .tag {
      color: #818181;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .benefits {
    display: flex;
    overflow: hidden;
    padding: 0;
    background-color: white;
    &.center {
      justify-content: space-around;
    }
    .benefit {
      float: none;
      width: auto;
      padding: 0;
      color: #007d7f;
      font-size: 14px;
      text-transform: capitalize;
      padding: 8px;
      display: flex;
      align-items: center;

      .benefit-icon {
        width: 20px;
        height: 20px;
        display: flex;

        :first-child {
          width: 20px;
          height: 20px;
          color: #007d7f;

          :not([fill='none']) {
            fill: #007d7f;
          }
        }
      }
    }

    .benefit-extras {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      line-height: 16px;
      width: 36px;
    }

    .benefits-tooltip {
      position: absolute;
      bottom: -14px;
      right: -170px;
      width: 176px;
      padding: 20px;
      color: black;
      background-color: white;
      font-size: 16px;
      border-radius: 4px;
      line-height: 20px;
      box-shadow: 0 10px 15px -3px rgba(34, 34, 34, 0.2),
        0px 4px 6px -2px rgba(34, 34, 34, 0.05);
      cursor: default;
      z-index: 50;

      @media (max-width: 710px) {
        bottom: -20px;
        right: -20px;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 136px;
        margin: 12px 0 0 0;
        padding: 0;
        color: white;
        background-color: #e86431;
        font-size: 14px;
        font-family: 'Proxima Nova Bold', sans-serif;
        border: none;

        &:focus-visible {
          outline: none;
        }

        &:hover {
          background-color: #a13a12;
        }
      }
    }
  }
}

.container-benefits {
  height: 2.25rem;
  margin-top: 18px;
}