.page-banner{
    position: relative;
    display: flex;
    max-height: 300px;
    @media(max-width: 950px){
      display: block;
    }
    @media(max-width: 450px){
      display: none;
    }
    .right-side-outer-container{
      display: flex;
      align-items: flex-start;
      width: 75%;
      padding-top: 32px;
      padding-left: 48px;
      @media(max-width: 1100px){
        padding-left: 30px;
        padding-right: 30px;
      }
      @media(max-width: 950px){
        width: 75%;
      }
      @media(max-width: 660px){
        padding: 20px 16px 0;
      }
      .quotation{
        font-size: 9.3rem;
        line-height: 7.5rem;
        font-weight: 900;
        margin-right: 10px;
        font-family: "Proxima Nova", sans-serif !important;
        color: #007D7F;
        @media(max-width: 600px){
          font-size: 7rem;
          line-height: 5.5rem;
          margin-right: 5px;
        }
      }
      @media(max-width: 950px){
        font-size: 8rem;
        line-height: 6.5rem;
      }
      .text{
        padding-bottom: 0;
        color: #000000;
        font-size: 1.8rem;
        font-weight: 900;
        font-family: "Proxima Nova Bold Italic", sans-serif !important;
        line-height: 2rem;
        @media(max-width: 1100px){
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
        @media(max-width: 600px){
          font-size: 1.3rem;
          line-height: 1.3rem;
        }
      }
      .source{
        color: black;
        font-size: 1.5rem;
        font-family: "Proxima Nova", sans-serif !important;
        line-height: 1.5rem;
        font-weight: 400;
        @media(max-width: 950px){
          font-size: 1.2rem;
          line-height: 1rem;
        }
      }
    }
    .banner-skeleton {
      position: absolute;
      height: 190px;
    }
  }