#jobs-section{
    position: relative;
    padding: 25px;
    background-color: white;
    width: 100%;
    min-height: 100%;
    .jobs-search{
      z-index: 100;
      @media(max-width: 720px){
        margin-top: 15px;
      }
    }
    .jobs-content{
      display: flex;
      position: relative;
      margin-top: 35px;
      z-index: 50;
      @media(max-width: 930px){
        display: block;
        overflow-y: scroll;
      }
      @media(max-width: 720px){
        margin-top: 25px;
      }
      .search-results{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 4px 29px 4px 4px;
        border-right: 2px solid #EBEBEB;
        height: calc(100vh - 55px);
        @media(max-width: 930px){
          text-align: center;
          border-right: none;
          height: 100%;
          padding: 4px 0 0;
        }
        @media(min-width: 720px){
          min-width: 400px;
        }
        .job-container {
          display: block;
          width: 100%;
          @media(max-width: 930px){
            width: 95%;
            margin: auto;
            margin-bottom: 20px;
          }
          .job{
            @media(max-width: 930px){
              text-align: left;
            }
          }
        }
        .pagination{
          display: flex;
          position: relative;
          top: 5px;
          margin-bottom: 5px;
          @media(max-width: 930px){
            justify-content: center;
          }
          .icon-container{
            cursor: pointer;
            .icon{
              color: #007D7F;
              font-size: 30px;
            }
          }
          .page-range{
            position: relative;
            top: 3px;
            margin-left: 25px;
            margin-right: 25px;
            font-size: 20px;
          }
        }
      }
    }
  }