@mixin new-button($color, $background, $border) {
  border: 3px solid $border;
  box-sizing: border-box;
  background: $background;
  color: $color;
  padding: 16px;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  @media(max-width: 890px){
    width: 100%;
  }
}

#profile-section{
  position: relative;
  padding: 25px;
  background-color: white;
  min-height: 980px;

    .title-button-container{
      display: flex;
      justify-content: space-between;

      .profile-title{
        padding-bottom: 0;
        color: #007D7F;
        font-size: 40px;
        font-weight: 900;
        font-family: "Proxima Nova Bold Italic", sans-serif !important;
        line-height: 60px;
        @media(max-width: 950px){
          font-size: 34px;
          font-weight: 700;
          line-height: 36px;
        }
    }
  }
  .outline-button-profile{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Proxima Nova';
    @include new-button(#E86431, transparent, #E86431);
    &:hover{
      color: #A13A12;
      border: 3px solid #A13A12;
    }
  }
  .fill-button-profile{
    @include new-button(#FFFFFF, #E86431, #E86431);
    font-size: 14pt;
    padding-top: 14px;
    padding-bottom: 14px;
    &:hover{
      background: #A13A12;
      border: 3px solid #A13A12;
    }
  }
  .fill-button-danger{
    @include new-button(#FFFFFF, #ED254E, #ED254E);
    &:hover{
      background: #bb1637;
      border: 3px solid #bb1637;
    }
  }
  .progress-header{
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #000;
    margin-top: 40px;
  }
  .progress-button-text{
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #007D7F;
    margin-left: 15px;
    margin-bottom: 0;
  }

  .profile-card.hide {
    opacity: 0 !important;
    transform: translateY(24px);
  }
  .profile-card.show {
    opacity: 1;
    transform: translateY(0);
  }
  .profile-card{
    transition: all 0.8s;
    overflow: hidden;
    box-shadow: 1px 2px 3px #818181;
    padding: 28px;
    position: relative;
    width: 95%;
    margin-bottom: 150px;
    @media(max-width: 810px){
      padding: 8px;
      width: 100%;
      margin-bottom: 40px;
    }

    .line-container{
      position: absolute;
      right: 0;
      bottom: 1%;
      right: -32px;
      top: 80px;
      @media(max-width: 435px){
        display: none;
      }
      @media(max-width: 890px){
        width: 150px;
        height: 116px;
      }
      @media(max-width: 1270px){
        top: 18%;
      }
    }
    .line-img{
      @media(max-width: 1270px){
        width: 350px;
      }
    }
    .img-logo-container{
      display: flex;
      justify-content: space-between;

      .logo-container{
        width: 120px;
        @media(max-width: 890px){
          width: 30px;
        }
      }
    }

    .bottom-section{
      margin-top: 70px;
      @media(max-width: 890px){
        margin-top: 20px;
      }
    }

    .social-container{
      display: flex;
      flex-wrap: wrap;
    }

    .name-header{
      font-family: Proxima Nova;
      font-size: 40px;
      font-style: italic;
      font-weight: 900;
      line-height: 60px;
      letter-spacing: 0em;
      color: #000;
      margin-bottom: 12px;
      @media (max-width: 890px) {
        font-size: 15.1665px;
        line-height: 19px;
        margin-bottom: 3px;
      }
    }
    .position-header{
      font-family: Proxima Nova;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      color: #818181;
      margin-bottom: 12px;
      @media (max-width: 890px) {
        font-size: 10.7429px;
        line-height: 11px;
        margin-bottom: 3px;
      }
    }
    .content{
      font-family: Proxima Nova;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: #818181;
      margin-bottom: 0;
      @media (max-width: 890px) {
        font-size: 7.58324px;
        line-height: 8px;
      }
    }
    .social-img{
      height: 20px;
      margin-right: 10px;
      @media (max-width: 890px) {
        height: 10px;
        margin-right: 3px;
      }
    }
    .social-spacing{
      margin-right: 32px;
      @media (max-width: 890px) {
        margin-right: 8px;
      }
    }
  }
}

.profile-edit-img-logo{
  display: flex;
  align-items: center;
  @media (max-width: 890px) {
    flex-direction: column;
    width: 100%;
  }

  .profile-edit-img-container{
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background: #FCED17;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 890px) {
      margin-bottom: 32px;
    }
  }
  .profile-edit-img{
    width: 80px;
    height: 80px;
    border-radius: 40px;
    object-fit: cover;
  }
}
input.new-input{
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  font-size: 14px;
  &:focus{
    border: 1px solid #E86431 !important;
  }
}
p.error-message{
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ED254E;
  margin-top: 8px;
}
.icon{
  position: absolute;
  right: 18px;
  color: #818181;
}
.input-label{
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
}
.line-container-edit{
  position: absolute;
  right: 0;
  top: 14%;
  @media(max-width: 890px){
    width: 150px;
    height: 116px;
  }
}
.radio-label{
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
  margin-left: 0.5rem;
}
.native-radio{
  display: none;
  &:checked + .custom-radio::after {
    transform: scale(1);
  }
}
.custom-radio{
  width: 28px;
  height: 28px;
  border: 1px solid #007D7F;
  border-radius: 50%;
  margin-right: 0.625rem;
  box-sizing: border-box;
  padding: 2px;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    top: -4px;
    left: -4px;
    bottom:-4px;
    right:-4px;
    display: block;
    border: 8px solid #007D7F;
    border-radius: 50%;
    transform: scale(0);
  }
}

.info-edit-inputs{
  display: flex;
  width: 42%;
  margin-top: 38px;
  @media(max-width: 890px){
    width: 100%;
    display: block;
    margin-top: 28px;
  }
}
.edit-input-container{
  flex-grow: 1;
  @media(max-width: 890px){
    margin-right: 0;
  }
}
.profile-edit-title{
  padding-bottom: 0;
  color: #007D7F;
  font-size: 40px;
  font-weight: 900;
  font-family: "Proxima Nova Bold Italic", sans-serif !important;
  line-height: 60px;
}
.profile-edit-sub-title{
  padding-bottom: 0;
  color: #007D7F;
  font-size: 24px;
  font-weight: 700;
  font-family: "Proxima Nova Bold", sans-serif !important;
  line-height: 24px;
  margin-top: 28px;
}
.flex-wrap{
  display: flex;
  flex-wrap: wrap;
}
.profile-p-header{
  margin-bottom: 0;
  margin-top: 12px;
  @media(max-width: 890px){
    margin-top: 28px;
  }
}

.profile-img-container{
  width: 185px;
  height: 185px;
  border-radius: 92px;
  background: #FCED17;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width: 890px){
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
}

.profile-img{
  width: 170px;
  height: 170px;
  border-radius: 85px;
  object-fit: cover;
  @media(max-width: 890px){
    width: 52px;
    height: 52px;
    border-radius: 26px;
  }
}

.img-letter{
  font-size: 100px;
  text-transform: capitalize;
  color: #000;
  margin: 0;
  @media(max-width: 890px){
    font-size: 40px;
  }
}

.edit-img-letter{
  font-size: 48px;
  text-transform: capitalize;
  color: #000;
  margin: 0;
}

.edit-profile-img{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.profile-edit-modal-title{
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #007D7F;
  margin: 48px 0 48px 0;
}

.modal-container{
  padding: 28px
}

.profile-edit-modal{
  &-buttons{
    display: flex;
    @media screen and (max-width: 890px) {
      flex-direction: column-reverse;
      gap: 16px;
      align-items: center;
    }
  }
}
