.FilterButtons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  @media screen and (min-width: 780px) {
    flex-direction: row;
  }
}

.button {
  &__text {
    font-size: 25px;
    font-style: italic;
    font-weight: 900;
    line-height: 55px;
    letter-spacing: 0em;
    text-align: center;
    color: #fced17 !important;
    z-index: 10;
    user-select: none;
  }

  &__background {
    cursor: pointer;
    text-align: center;
    width: 100%;
    height: 58px;
    background-blend-mode: multiply, normal;
    border-radius: 40px;
    z-index: 1;
    background: linear-gradient(0deg, #767676, #767676),
      url('../images/computer.jpeg');
    background-position: center;
    background-size: 100%;
    transition: background-size 200ms ease-out;
    &--active {
      background: linear-gradient(0deg, #007d7f, #007d7f),
        url('../images/computer.jpeg');
      background-position: center;
      background-size: 100%;
    }
    &:hover {
      background-size: 120%;
    }
    @media screen and (min-width: 780px) {
      width: 223px
    }
  }
}
