.Button {
	all: unset;
  transition: 0.5s;
	text-align: center;
	cursor: pointer;
	padding: 13px 8px;
	border-radius: 8px;
	background: #E86431;
	text-transform: uppercase;
	color: #FFFFFF;
	font-family: 'Proxima Nova Bold';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	border: 3px solid #E86431;
	&:hover{
		background: #A13A12;
		border: 3px solid #A13A12;
	}
	&--outline, &--outline:hover{
		background: transparent;
		color: #E86431;
		&:hover {
			color: #A13A12;
		}
	}
}
