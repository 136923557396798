@import "./base/_variables.scss";
@import './_grid-settings.scss';
@import './neat/neat';

body {
  -webkit-font-smoothing: antialiased;
  background-color: $white;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  line-height: $header-line-height;
  margin: 0;
  font-weight: $light-font-weight;
  text-transform: none;
  text-rendering: optimizeLegibility; // Fix the character spacing for headings
}

h1 {
  font-size: $responsive-h1-font-size;
  padding-bottom: $h1-bottom-padding;
  color: $blackish;

  @include media($medium-screen) {
    font-size: $h1-font-size;
  }
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $responsive-h3-font-size;
  @include media($medium-screen) {
    font-size: $h3-font-size;
  }
}

h4 {
  font-size: $h4-font-size;
  line-height: $standard-padding*1.33;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

p {
  margin: 0 0 (calc($base-spacing / 2));
  font-size: $p-font-size;
  line-height: $standard-padding;
}

a {
  // @include transition(color 0.1s linear);
  color: $teal;
  text-decoration: none;
  &:hover {
    color: $teal-light;
  }
  &:active, &:focus {
    color: $teal-dark;
    outline: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

blockquote {
  border-left: 2px solid $base-border-color;
  color: lighten($base-font-color, 15);
  margin: $base-spacing 0;
  padding-left: calc($base-spacing / 2);
}

cite {
  color: lighten($base-font-color, 25);
  font-style: italic;

  &:before {
    content: "\2014 \00A0";
  }
}
