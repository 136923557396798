.eventDashboardCardContainer {
  display: inline-block;
  height: 100%;

  @media (min-width: 62.5em) {
    flex-basis: 33.3%;
  }
  @media (min-width: 100em) {
    flex-basis: 20%;
  }
  @media (max-width: 768px) {
    width: inherit;
  }
}
.eventCardContainer, .eventFavoriteCardContainer {
  min-width: 240px;
  width: 100%;
  @media (min-width: 861px) {
    min-width: 268px;
    width: calc(50% - 12px);
  }
  @media (min-width: 1430px) {
    min-width: 384px;
    width: calc(33.33% - 24px);
  }
}

.eventPage {
  .eventCardContainer {
    margin-right: 0;
  }
}

.eventCard {
  transition: transform 300ms ease-in-out, margin 300ms ease-in-out;

  width: 100%;
}

article.eventDashboardCard {
  width: 280px;
  height: 100%;
  transition: all 100ms ease-out;
  @media (min-width: 861px) {
    width: 332px;
  }
  &:hover, &:active {
    outline: 4px solid #7FC3C4;
  }
}
.eventFavoriteCard {
  width: min(75vw, 390px);
}

.eventCard,
.eventDashboardCard,
.eventFavoriteCard {
  position: relative;
  // margin-bottom: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  flex-direction: column;
  // margin-right: 25px;
  border-radius: 8px;
  min-width: 240px;
  @media (min-width: 861px) {
    min-width: 268px;
  }
  @media (min-width: 1430px) {
    min-width: 384px;
  }

  .header {
    height: 100px;
    display: flex;
    &__image {
      width: 100px;
      background-color: #c4c4c4;
      border-top-left-radius: 8px;
    }
    &__information {
      color: black;
      padding: 12px;
      flex: 1;
      background: rgba(196, 196, 196, 0.19);
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-top-right-radius: 8px;
      .information__day {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        &--bold {
          font-weight: 700;
        }
        :last-child {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    &__type {
      position: absolute;
      background-color: #bb27ab;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 12px;
      &Text {
        margin: 8px;
      }

      &--gray {
        background-color: #ebebeb;
        color: #000;
      }

      &--featured {
        background-color: teal;
        color: #fff;
        top: 58px;
      }
    }
  }

  .body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .favorite {
      align-self: center;
      color: #e86431;
      background-color: transparent;
      font-size: 21px;
      cursor: pointer;
    }

    &__heading {
      display: flex;
      justify-content: space-between;
    }
    &__title {
      height: 40px;
      margin-bottom: 8px;
      color: black;
      font-family: "Proxima Nova Bold", sans-serif !important;
      font-style: normal !important;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.1;
      white-space: pre-wrap;
      overflow: hidden;
    }
    &__recurring {
      color: black;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 16px;
      height: 16px;
    }
    &__community {
      color: #818181;
      font-size: 14px;
      margin-bottom: 8px;
      white-space: pre-wrap;
      overflow: hidden;
      line-height: 16px;
    }
    &__text {
      color: black;
      line-height: 16px;
      font-size: 14px;
      white-space: pre-wrap;
      overflow: hidden;
      height: 64px;

      p {
        font-size: inherit;
        line-height: inherit;
        overflow-x:hidden;
      }

      a {
        font-size: inherit;
        line-height: inherit;
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
      margin-top: 8px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 110px;
        margin-right: 15px;
        color: white;
        background-color: #e86431;
        font-size: 14pt;
        font-weight: 700;
        &:hover {
          background-color: #a13a12;
        }
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 99px;
        margin: 0;
        padding: 0;
        color: #e86431;
        background-color: white;
        border: 2px solid #e86431;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        border-radius: 8px;
        line-height: 16px;
        font-family: "Proxima Nova Bold";
        width: 100px;
        &:hover {
          color: #a13a12;
          border-color: #a13a12;
          .favorite-icon,
          .copy-link {
            color: #a13a12;
          }
        }
        &.copy-link-button {
          display: none;
        }
        &.event-registerbtn {
          width: 100px;
          margin-right: 15px;
          color: white;
          background-color: #e86431;
          &:hover {
            background-color: #a13a12;
          }
        }
        .favorite-icon,
        .copy-link {
          position: relative;
          top: -2px;
          margin-right: 10px;
        }
      }
      .copy-link {
        color: #e86431;
        font-size: 21px;
        cursor: pointer;
        width: 21px;
        height: 21px;
      }
      .mobile-copy-link-container {
        margin-left: auto;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
