@import './base/_variables.scss';
@import './base/_buttons.scss';
@import './base/_lists.scss';
@import './_mixins.scss';
@import './_grid-settings.scss';
@import './neat/neat';
.plan {
    @include pad(1em $text-vertical-pad);
    @include span-columns(6 of 12);
    td {
      width: 100%;
      border-bottom: none;
    }
    .square {
      width: $standard-padding;
      height: $standard-padding;
    }

    @media screen and ($large-screen-down) {
      @include span-columns(12 of 12);
      @include pad($mobile-text-vertical-pad);
    }
  }

  //JOBS INDEX
  .job-card-wrapper {
    background-color: $white;
    padding: calc($standard-padding / 2);
    color: $blackish;
    margin: 0 0 $standard-padding 0;
    list-style: none;
    word-break: break-word;
    line-height: $standard-padding;
    a {
      cursor: pointer;
      color: $base-font-color;
      .arrow {
        // background-image: url(asset_path("about/arrow-large.svg"));
        display: inline-block;
        width: $standard-padding * 1.333;
        height: calc($standard-padding / 2);
        background-repeat: no-repeat;
        position: relative;
        top: calc($standard-padding / -10);
        @media screen and ($medium-screen-down) {
          width: $standard-padding;
          height: calc($standard-padding / 3);
        }
      }
      &:hover {
        h3 {
          color: $teal-dark;
        }
        .arrow {
          // background-image: url(asset_path("about/arrow-large-dark.svg"));
          right: calc($standard-padding / -10);
        }
        img {
          right: calc($standard-padding / -5);
        }
      }
    }
    .experience-level {
      cursor: pointer;
      text-transform: capitalize;
    }
    .location-teal {
      color: $teal;
      cursor: pointer;
      display: inline;
    }
    h3 {
      color: $teal;
      display: inline;
      font-weight: $light-font-weight;
      &:hover {
        color: $teal-dark;
      }
    }
    p {
      margin-bottom: 0;
    }
    svg {
      float: left;
      max-width: calc($standard-padding / 2);
      display: block;
      height: calc($standard-padding / 1.875);
      margin: 0 auto;
      margin-top: calc($standard-padding / 5);
      margin-right: calc($standard-padding / 6);
    }
    ul {
      margin-left: -5px;
      li {
        background-color: $teal;
        display: inline-flex;
        color: $white;
        padding: calc($standard-padding / 3);
        margin: calc($standard-padding / 6);
        &.stack {
          background: transparent;
          font-weight: bold;
          color: $base-font-color;
          padding: 0;
          cursor: pointer;
          p {
            line-height: calc($standard-padding / 2);
          }
          .line {
            color: $base-font-color;
          }
          &:last-of-type {
            .line {
              color: $white;
            }
          }
        }
        .name {
          position: relative;
          cursor: pointer;
          top: calc($standard-padding / 7.5);
        }
      }
    }
    .company {
      display: inline-block;
      font-weight: bold;
      margin-bottom: 0;
      cursor: pointer;
    }
    .location {
      cursor: pointer;
      display: inline;
      &.teal {
        color: $teal;
      }
    }
  }

  // ORDER SUMMARY PAGE
  #summary-price-amount {
    color: $red;
    font-weight: bold;
    font-size: $h6-font-size;
  }
  #summary-overview {
    padding-top: $text-vertical-pad;
  }
  #summary-price {
    padding-top: $text-vertical-pad * 2;
  }
  #summary-note {
    padding-top: $text-vertical-pad;
    font-size: $smaller-p-font-size;
    color: $light-gray;
    &.hidden {
      display: none;
    }
  }

  #job-schedule {
    @include pad($standard-padding * 3 $standard-padding);
    #note-alert {
      background: transparent;
      color: $red;
      text-align: left;
      padding: 0;
    }
  }

  // JOB POST FORM
  #JobPostsForm {
    background: $white;
    @include pad($standard-padding * 4 0 0);
    .container {
      max-width: $max-container-width + $standard-padding;
      &.form-area {
        padding: $standard-padding;
        &.about {
          padding-top: 0;
        }
        &.logo {
          padding-bottom: 0;
          #job_logo_url {
            display: none;
          }
        }
        &.submit {
          padding: 0 $standard-padding;
          input[type="submit"] {
            margin: 0;
          }
          @media screen and ($super-large-screen-down) {
            padding: 0;
          }
        }
      }
    }
    #company-overview {
      padding-top: $standard-padding;
    }
    .form-section {
      margin: 0 $standard-padding * -1;
      @media screen and ($super-large-screen-down) {
        margin: 0;
      }
      .form-field {
        display: inline-block;
        vertical-align: top;
        margin: 0;
        padding: calc($standard-padding / 3) $standard-padding;
        @media screen and ($medium-screen-down) {
          padding: calc($standard-padding / 3) 0;
          margin: 0;
        }
        &.hidden {
          display: none;
        }
      }
    }
    .inputError {
      color: $red;
      display: block;
      font-size: $base-font-size;
      font-weight: $light-font-weight;
      letter-spacing: calc($standard-padding / 10);
      margin-top: calc($standard-padding / 3);
      padding-bottom: calc($standard-padding / 3);
      text-transform: none;

      span {
        display: block;
      }
    }
    ol {
      list-style: decimal;
      padding-left: calc($standard-padding / 1.5);
    }
    .sub-step {
      line-height: $standard-padding;
    }
    #logo-upload-container {
      color: $light-gray;
      button {
        padding: calc($standard-padding / 2);
        height: $logo-size;
        &.upload-logo {
          color: $blackish-rgba;
          font-size: $base-font-size;
          background: $white;
          border: 3px dashed $blackish-rgba;
          text-transform: none;
          width: 100%;
          max-width: $standard-padding * 10;
        }
        &.edit-logo {
          text-transform: none;
          text-decoration: underline;
          color: $dark-gray;
          font-size: $smaller-p-font-size;
          padding: 0;
          margin: 0;
          background: transparent;
          border: none;
          &:hover {
            color: $teal;
            background: transparent;
          }
        }
      }
      img {
        height: $logo-size;
      }
    }
    #submit-area {
      input[type="submit"] {
        margin: $standard-padding 0;
      }
    }
    #stack-area {
      &.error {
        input {
          border: calc($standard-padding / 10) solid $red;
        }
      }
      input {
        background: $whitish;
      }
      .selected-stack {
        background-color: $teal;
        color: $white;
        padding: calc($standard-padding / 2);
        display: inline-block;
        padding-right: $standard-padding * 2;
        margin-top: calc($standard-padding / 2);
        margin-right: calc($standard-padding / 2);
        span {
          position: relative;
          top: 4px;
        }
      }
      #stack-input {
        max-width: $standard-padding * 10;
      }
      .highlighted {
        color: red;
      }
      .x {
        font-size: $standard-padding;
        transform: rotate(45deg);
        cursor: pointer;
        right: calc($standard-padding / 2);
        display: flex;
        float: right;
        width: 23px;
        height: 28px;
        margin: 0;
        position: relative;
        top: -2px;
        right: -45px;
      }
    }
    #applicant-contact {
      background-color: $whitish;
      input {
        background: $whitish;
        &[type="email"] {
          max-width: $standard-padding * 12;
        }
        &[type="text"] {
          max-width: $standard-padding * 12;
        }
      }
      .input-container {
        padding-left: $standard-padding * 1.5;
      }
      .plan {
        width: 100%;
        padding: calc($standard-padding / 1.5) 0 0 0;
      }
      .plan-description {
        padding-left: $standard-padding * 2;
      }
      .square {
        background-color: $whitish;
        margin-right: $standard-padding;
      }
    }
    #job-experience-level {
      background-color: $whitish;
      padding-bottom: calc($standard-padding / 3);
      li {
        list-style: none;
        margin-left: 0;
      }
    }
    #job-salary {
      .col-6 {
        margin-right: 0;
        padding: 0;
      }
      input {
        @include span-columns(5 of 12);
        margin: calc($standard-padding / 15);
        background-repeat: no-repeat;
        padding-right: 0;
        &.currency {
          background-image: none;
          display: inline;
          float: none;
          margin: 2px 0 0 0;
          padding-right: calc($standard-padding / 6);
          padding-left: 0;
          width: 100%;
        }
        &.max {
          margin-left: calc($standard-padding / 5);
        }
      }
      p {
        @include span-columns(1 of 12);
        text-align: center;
        padding-top: calc($standard-padding / 1.5);
      }
      #salary-errors {
        line-height: calc($standard-padding / 1.2);
        margin-top: calc($standard-padding / -1.2);
      }
      select.intentional-select {
        width: 10.938rem;
        float: none;

        @media screen and ($super-large-screen-down) {
          margin-top: $standard-padding;
        }
      }
    }
    #address-area {
      span p {
        margin-top: calc($standard-padding / 2);
      }
    }
    #job-stack {
      background-color: $whitish;
    }
    #job-benefits {
      background-color: $white;
      color: $base-font-color;
      .benefit-title {
        overflow-wrap: break-word;
        color: $dark-font-color;
        font-size: $base-font-size;
        padding-top: calc($standard-padding / 1.5);
        height: 45px;
        text-align: left;
        padding-left: 50px;
        @media screen and ($medium-screen-down) {
          padding-left: $standard-padding * 1.5;
          font-size: $base-font-size * 0.9;
        }
      }
      input[type="checkbox"] + label {
        background-color: $white;
      }
      textarea {
        color: $base-font-color;
      }
      .stack-list-additions {
        max-width: $standard-padding * 10;
      }
      ul {
        @include outer-container;
        display: flex;
        flex-wrap: wrap;
        li {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: flex-end;
          flex: 0 0 33.33%;
          padding-bottom: calc($standard-padding / 2);
          @media screen and ($large-screen-down) {
            flex: 0 0 50%;
            padding-bottom: 0;
            .benefit-title {
              padding-top: calc($standard-padding / 1.5);
            }
          }
          @media screen and ($medium-screen-down) {
            flex: 0 0 100%;
            padding-bottom: 0;
            li {
              padding-bottom: 0;
              margin-bottom: 0;
            }
          }
          &.seniority_level {
            @include span-columns(12 of 12);
            padding-left: 0;
            label {
              float: left;
            }
            .copy {
              padding-left: $standard-padding * 2;
            }
            .short-description {
              color: $teal;
              font-weight: bold;
            }
          }
          &.stack {
            @include pad(calc($standard-padding / 6));
            @include span-columns(2.4 of 12);
            @media screen and ($large-screen-down) {
              @include span-columns(4 of 12);
            }
          }
          .benefit-icon {
            margin: 0 auto;

            svg {
              fill: $white;
              margin-right: calc($standard-padding / 3);

            }
          }
        }
      }
    }
    #seniority_level {
      input {
        border-bottom: 0;
        background-color: transparent;
        float: left;
        &[type="radio"] {
          display: none;
        }
      }
      .square {
        width: $standard-padding;
        height: $standard-padding;
      }
    }
    #job-plans {
      width: 100%;
      @include outer-container(100%);
      @include pad(calc($standard-padding / 2));
      h5 {
        color: $dark-font-color;
        text-transform: capitalize;
        font-weight: $light-font-weight;
      }
      .plan {
        input[type="radio"] {
          display: none;
        }
        .plan-description {
          margin-left: 50px;
          &.sold-out {
            color: $base-font-color;
            opacity: 0.7;
          }
          .title {
            color: $teal;
            float: left;
            font-size: 1.4em;
            @media screen and ($large-screen-down) {
              width: 100%;
              margin-bottom: 10px;
            }
          }
          ul {
            // list-style-image: url(asset_path("jobs/dash.svg"));
            padding: 0 calc($standard-padding / 2) $standard-padding;
            height: auto;
            @media screen and ($super-large-screen-down) {
              height: auto;
            }
            li {
              font-size: $base-font-size * 1.2;
              text-align: left;
              line-height: $standard-padding;
            }
          }
        }
        .square {
          &.sold-out {
            // background-image: url(asset_path("jobs/x-gray.svg"));
            border: 3px solid $medium-gray;
            pointer-events: none;
          }
        }
      }
      .sold-out {
        color: $red;
        pointer-events: none;
      }
    }
    #remote {
      padding-top: calc($standard-padding / 6);
      @media screen and ($super-large-screen-down) {
        padding-top: 0;
        padding-bottom: $standard-padding;
      }
      .label {
        position: relative;
        padding-left: $standard-padding * 1.5;
        top: calc($standard-padding / 2.5);
        @media screen and ($medium-screen-down) {
          top: calc($standard-padding / 1.2);
        }
      }
      input[type="checkbox"] + label {
        float: left;
        top: $standard-padding * -1;
        position: relative;
        z-index: 1;
        @media screen and ($super-large-screen-down) {
          top: 0;
        }
      }
    }
    .salary {
      color: $blackish;
      cursor: pointer;
      &:hover {
        color: $blackish;
      }
      &.hidden {
        display: none;
      }
    }
    #no-events-note {
      padding: $standard-padding;
    }
  }

  // JOBS show
  #jobs-show {
    background: $white;
    line-height: $standard-padding;
    padding: $standard-padding * 3.33 0 0 0;
    a {
      color: $blackish;
    }
    button {
      border: none;
      background: $teal;
      letter-spacing: 1px;
      margin: 0;
      margin-top: calc($standard-padding / 2);
      @include pad(calc($standard-padding / 2) $standard-padding * 2);
    }
    h5 {
      font-weight: bold;
      padding: 0 0 calc($standard-padding / 3) 0;
    }
    #benefits {
      @include outer-container;
      @include clearfix;
      margin-bottom: calc($standard-padding / -3);
      .benefit {
        display: inline-block;
        padding: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
        .square {
          cursor: pointer;
          color: $navy-bluish;
          margin: calc($standard-padding / 6);
          margin-left: 0;
          margin-right: calc($standard-padding / 3);
          background: $white;
          position: relative;
          border: 0;
        }
        .benefit-content {
          background: transparent;
          border: 2px solid $teal;
          cursor: pointer;
          padding: calc($standard-padding / 3);
          color: $white;
          p {
            color: $teal;
            line-height: 20px;
            padding-left: 40px;
            position: relative;
            top: calc($standard-padding / 6);
          }
        }
        svg {
          fill: $teal;
          display: block;
          float: left;
          margin: 0 auto;
          margin-bottom: calc($standard-padding / 7);
        }
      }
    }
    .company {
      margin-bottom: 0;
    }
    #description {
      padding: calc($standard-padding / 1.2) 0 calc($standard-padding / 2) 0;
      @media screen and ($large-screen-down) {
        padding-top: calc($standard-padding / 2);
      }
    }
    .experience-level {
      cursor: pointer;
      text-transform: capitalize;
    }
    #hiring-process-area {
      background-color: $whitish;
      ul {
        list-style: none;
        margin-left: calc($standard-padding / -2);
      }
    }
    .job-info {
      padding: $standard-padding;
    }
    #job-responsibilites {
      padding: calc($standard-padding / 1.5) 0 0 0;
    }
    .location {
      color: $blackish;
      min-height: $standard-padding * 1.33;
      margin-top: calc($standard-padding / -3);
      .black-link {
        color: $blackish;
      }
      span {
        cursor: pointer;
      }
      svg {
        width: calc($standard-padding / 2);
        margin-right: calc($standard-padding / 6);
        height: calc($standard-padding / 1.875);
      }
    }
    #company-overview {
      padding: calc($standard-padding / 1.5) 0 0 0;
      p {
        margin: 0;
      }
    }
    #responsibilities {
      background: $whitish;
      ol {
        list-style: decimal;
        -webkit-padding-start: calc($standard-padding / 1.2);
      }
    }
    #stacks {
      padding-top: $standard-padding;
      .stack-name {
        display: inline-block;
        strong {
          color: $blackish;
        }
        &.line {
          color: $blackish;
          font-weight: bold;
        }
      }
    }
    .square-logo {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $white;
      border: calc($standard-padding / 3) solid $white;
      height: $standard-padding * 5.6;
      margin-left: calc($standard-padding / -3);
      width: $standard-padding * 5.6;
      float: left;
      margin-right: calc($standard-padding / 2);
      @media screen and ($large-screen-down) {
        float: none;
        margin-bottom: calc($standard-padding / 2);
        height: $standard-padding * 3;
        width: $standard-padding * 3;
      }
    }
    ul {
      list-style: disc;
      padding-top: 0;
    }
    li {
      line-height: $standard-padding;
      padding-top: 0;
      margin-top: 0;
      margin-left: calc($standard-padding / 2);
    }
  }
  #job-email-form {
    background: $white;
    label {
      display: initial;
    }
    h2 {
      padding-bottom: $standard-padding;
    }
    .email {
      padding-right: $standard-padding;
      @include span-columns(6 of 12);
      @media screen and ($large-screen-down) {
        padding-right: 0;
        @include span-columns(12 of 12);
      }
    }
    .portfolio {
      padding-left: $standard-padding;
      @include span-columns(6 of 12);
      @media screen and ($large-screen-down) {
        padding-left: 0;
        @include span-columns(12 of 12);
        margin-top: $standard-padding;
      }
    }
    .message {
      @include span-columns(12 of 12);
      line-height: $standard-padding;
      margin-top: $standard-padding;
      label {
        display: block;
        margin: 0;
      }
    }
  }
  #job-stub {
    color: $white;
    background: $navy-bluish;
    @include pad($standard-padding);
    button,
    #purchaseButton {
      font-size: $base-font-size;
      border: 3px solid $teal;
      padding: calc($standard-padding / 2) $standard-padding * 2;
      outline: none;
      margin: 0;
      letter-spacing: 1px;
      white-space: inherit;
      width: auto;
      &:hover {
        color: $white;
        background-color: $teal-button-hover;
        border: 3px solid $teal-button-hover;
      }
      &:focus {
        color: $white;
        background-color: $teal-button-focus;
        border: 3px solid $teal-button-focus;
      }
      @media screen and ($small-screen-down) {
        padding: calc($standard-padding / 3) calc($standard-padding / 2);
      }
    }
    h5 {
      font-weight: $light-font-weight;
      padding-bottom: calc($standard-padding / 3);
    }
    p {
      padding-bottom: calc($standard-padding / 3);
    }
    .job-stub-container {
      @include pad($standard-padding);
    }
  }

  // JOBS index
  #jobs {
    background-color: $whitish;
  }
  #job-board-wrapper {
    background-color: $whitish;
    padding-bottom: $standard-padding * 2;
    @include pad(0 0 $standard-padding);
    .jobs-scroll {
      padding: 0 calc($standard-padding / 2);
    }
    .results {
      padding: calc($standard-padding / 2) 0 0 calc($standard-padding / 2);
      p {
        font-size: calc($standard-padding / 1.5);
      }
      .number {
        color: $teal;
        font-weight: bold;
      }
    }
    h2 {
      font-weight: $light-font-weight;
      padding: 0 $standard-padding;
    }
    #no-jobs-notice {
      @include pad($standard-padding);
      text-align: center;
    }
  }
  .post-job-banner {
    background: $whitish;
    padding: $standard-padding calc($standard-padding / 2);
    @include clearfix;
    .container {
      clear: both;
      background: $navy-bluish;
      color: $white;
      text-align: center;
      padding: $standard-padding * 2 calc($standard-padding / 2);
    }
    a {
      border-bottom: calc($standard-padding / 6) solid $link-teal;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        color: $white;
        background: $link-teal;
      }
    }
    h2 {
      font-weight: $light-font-weight;
    }
    p {
      margin: 0;
      padding-top: calc($standard-padding / 2);
      letter-spacing: 1px;
    }
  }

  // JOBS about
  #jobs-about {
    .teal-text {
      color: $teal;
    }
    .white-text {
      color: $white;
      margin-bottom: 0;
      margin-top: $standard-padding * -1;
      padding-top: calc($standard-padding / 15);
      font-size: $base-mobile-input-size;
      letter-spacing: calc($standard-padding / 15);
    }
    .jobs-about-hero {
      color: $blackish;
      text-align: center;
      padding-top: $standard-padding * 4;
      padding-bottom: $standard-padding * 1.5;
      .text {
        padding: 0 $standard-padding;
      }
    }
  }
  #job-plan-pricing-table {
    padding-top: $standard-padding;
    max-width: $standard-padding * 32.5;
    display: block;
    margin: 0 auto $standard-padding * 0.83 auto;
    @media screen and ($large-screen-down) {
      padding-top: 0;
    }
    &.plan-show-page {
      max-width: 100%;
      padding: $standard-padding * 2 0;
      text-align: center;
      margin-bottom: 0;
      .row {
        display: block;
      }
      .includes-area {
        padding: calc($standard-padding / 6) 0 calc($standard-padding / 2);
        button {
          font-size: $base-mobile-input-size;
          width: 100%;
          margin: 0;
          padding: calc($standard-padding / 2);
          outline: none;
        }
        p {
          font-size: $base-mobile-input-size;
        }
      }
      .title {
        color: $blackish;
        font-size: $standard-padding;
        line-height: $standard-padding * 1.33;
        margin-top: $standard-padding * 2;
        text-align: center;
        padding: 0 $standard-padding;
      }
      .duration {
        opacity: 0.6;
        font-size: $base-mobile-input-size;
        font-weight: bold;
        margin-top: calc($standard-padding / -2);
      }
      .plan-wrapper {
        width: $standard-padding * 13;
        margin: 0 auto;
        @media screen and ($large-screen-down) {
          max-width: none;
        }
      }
    }
    .row {
      display: flex;
      @media screen and ($large-screen-down) {
        display: block;
      }
    }
    .plan-wrapper {
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      flex-direction: column;
      padding: calc($standard-padding / 2);
      @media screen and ($large-screen-down) {
        max-width: $standard-padding * 11;
        padding: $standard-padding;
        margin: 0 auto;
      }
      &.popular {
        background-color: $teal;
        margin-top: calc($standard-padding / -2);
        padding: $standard-padding calc($standard-padding / 2) calc($standard-padding / 2) calc($standard-padding / 2);
        @media screen and ($large-screen-down) {
          font-size: $base-mobile-input-size;
          padding: $standard-padding;
        }
      }
    }
    .plan {
      background-color: $white;
      font-size: $base-mobile-input-size;
      padding: 0 calc($standard-padding / 2);
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      border: calc($standard-padding / 15) solid $teal;
      @media screen and ($large-screen-down) {
        float: none;
      }
      .bold-text {
        font-weight: bold;
        font-size: $base-mobile-input-size;
        margin-top: calc($standard-padding / -1.5);
        margin-bottom: 0;
      }
      .cost {
        opacity: 0.6;
        font-size: $base-mobile-input-size;
        font-weight: bold;
        margin-top: calc($standard-padding / -2);
      }
      .description {
        text-align: left;
        font-size: $base-mobile-input-size;
        margin-top: calc($standard-padding / -2);
        padding: $standard-padding calc($standard-padding / 2) calc($standard-padding / 2) calc($standard-padding / 2);
        p {
          font-size: $base-mobile-input-size;
          font-weight: bold;
        }
        ul {
          list-style: disc;
        }
      }
      .button-area {
        padding: calc($standard-padding / 2) 0;
        text-align: left;
        margin-top: auto;
        button {
          font-size: $base-mobile-input-size;
          width: 100%;
          margin: 0;
          padding: calc($standard-padding / 2);
          text-transform: capitalize;
          outline: none;
        }
      }
      .plan-name {
        text-transform: uppercase;
        font-size: $base-mobile-input-size;
        padding-top: calc($standard-padding / 2);
        padding-bottom: calc($standard-padding / 6);
      }
      .price {
        color: $blackish;
        line-height: $standard-padding * 1.66;
        font-size: $standard-padding * 2;
        padding-top: calc($standard-padding / 2);
        span {
          font-size: $standard-padding;
        }
      }
    }
  }
  #jobs-logo-area {
    background-color: $whitish;
    padding: $standard-padding * 2 $standard-padding;
    .text {
      text-align: center;
      p {
        color: $blackish;
        font-size: $standard-padding * 1.16;
        line-height: $standard-padding * 1.5;
      }
    }
    .logos {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: space-around;
      .logo {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: $standard-padding * 3;
        transition: 0.5s;
        margin-bottom: calc($standard-padding / 2);
        width: $company-logo-width;
        &:hover {
          opacity: 0.6;
        }
        img {
          max-height: $standard-padding * 2.5;
          max-width: $standard-padding * 5;
          @media screen and ($large-screen-down) {
            height: auto;
            padding: 0 calc($standard-padding / 6);
          }
          &.Plex {
            max-height: $standard-padding;
          }
        }
      }
    }
  }
  #jobs-faq {
    padding: $standard-padding * 2 $standard-padding;
    h1 {
      font-size: $standard-padding;
    }
    h4 {
      font-weight: bold;
      font-size: calc($standard-padding / 1.5);
    }
    .faq-area {
      color: $blackish;
      padding-bottom: $standard-padding;
      outline: none;
      ul {
        list-style-type: disc;
        line-height: $standard-padding;
        padding-left: calc($standard-padding / 1.5);
      }
      img {
        margin-top: calc($standard-padding / 2);
        max-width: $standard-padding * 33;
        &.desktop {
          @media screen and ($medium-screen-down) {
            display: none;
          }
        }
        &.mobile {
          @media screen and ($medium-screen-up) {
            display: none;
          }
        }
      }
    }
    #social-boost {
      display: block;
      padding-top: $standard-padding * 3;
      margin-top: $standard-padding * -3;
    }
  }
