.ProgressBar {
  &__Steps {
    all: unset;
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
  }
}

.progress-container{
  background: #EBEBEB;
  height: 10px;
  border-radius: 8px;
  margin-bottom: 16px;

  .progress{
    border-radius: 8px;
    background: #007D7F;
    height: 100%;
  }
  display: none;
  @media (min-width: 1001px) {
    display: block;
  }
}
.step-container{
  margin-top: 0;
  @media (min-width: 1001px) {
    margin-top: 8px;
  }
}

div.skip-button{
  all: unset;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (min-width: 1001px) {
    top: 8px;
    right: 0;
  }
}
.skip-text{
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #007D7F;
  margin-right: 2px;
}
