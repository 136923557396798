.Text {
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #000;
	@media (min-width: 1001px) {
		font-size: 24px;
		line-height: 28px;
	}
}