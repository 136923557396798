@import "./base/_variables.scss";
@import './_grid-settings.scss';
@import './neat/neat';

.form-note {
  line-height: $standard-padding - 5px;
  word-break: break-word;
  &.link-text {
    word-break: break-all;
  }
}
#coupon-code-input-error {
  float: left;
}
.character-count {
  margin-bottom: calc($standard-padding/2);
}
input {
  border: none;
  box-shadow: none;
  width: 100%;
  font-size: $base-font-size;
  letter-spacing: 2px;
  border-bottom: 3px solid $blackish;
  padding: 20px 25px 25px 0;
  &:active {
    box-shadow: none;
    outline: none;
    border-bottom: 3px solid $blackish;
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &.error {
    border: 3px solid $red;
    &:focus {
      color: $red;
    }
  }
  &[type="search"] {
    // @include appearance(none);
  }
  &[type="checkbox"] {
    border-bottom: 0;
    display: inline;
    -moz-appearance: none;
    margin-right: calc($base-spacing / 4);
    float: left;
  }
  &[type="file"] {
    padding-bottom: calc($base-spacing / 2);
    width: 100%;
  }
  &[type="number"] {
    &::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &[type="submit"] {
    color: $white;
    border-bottom: none;
    background: $teal;
    width: 100%;
    padding: 20px;
    font-weight: bold;
    letter-spacing: 3px;
    &:hover {
      background-color: $teal-light;
    }
    &:focus {
      background-color: $teal-dark;
    }
  }
}

@mixin datalist-arrow__arrow() {
  color: $dark-gray;
  content:'\25bc';
  font-size: .75em;
  margin-bottom: $standard-padding;
  margin-left: calc($standard-padding / -1.5);
  pointer-events:none;
}

.datalist-arrow {
  &.datalist-currency {
    display: block;
    float: left;
    margin-right: calc($standard-padding/2);
    width: 27.49666%;

    @include media($super-large-screen-down) {
      margin-top: $standard-padding;
    }
  }

  /* Separate b/c IE/Edge can't parse */
  &:focus-within {
    &:after {
      @include datalist-arrow__arrow();
    }
  }

  &:hover {
    &:after {
      @include datalist-arrow__arrow();
    }
  }

  input {
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  select {
    background-image: none;
    border: $datalist-border;
    margin-bottom: 0;
    -webkit-appearance: auto;
    -webkit-box-shadow: $datalist-box-shadow;
    width: auto;
    z-index: 2;
  }
}

textarea {
  width: 100%;
  position: relative;
  line-height: $standard-padding - 5px;
  border: 3px solid $blackish;
  padding: calc($standard-padding/2);
  height: $logo-size * 2;
  resize: none;
  &:active {
    border: 3px solid $blackish;
    outline: none;
  }
  &:focus {
    border: 3px solid $blackish;
    outline: none;
  }
  &.error {
    border: 3px solid $red;
  }
  &:required, &:invalid {
    box-shadow: none;
  }
}

select {
  background: $white;
  // background-image: url(asset_path("jobs/dropdown.svg"));
  background-size: $standard-padding * 2 $standard-padding * 2;
  background-repeat: no-repeat;
  background-position: right;
  border: calc($standard-padding/10) solid $blackish;
  width: 100%;
  max-width: 350px;
  margin-bottom: $standard-padding;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0px;
  padding: 20px;
  outline: none;
  &.error {
    border: calc($standard-padding/10) solid $red;
  }
  &::-ms-expand {
    display: none;
  }
}

label {
  color: $blackish;
  display: block;
  font-size: 19px;
  letter-spacing: calc($standard-padding/10);
  margin-top: $standard-padding;
  text-transform: uppercase;
  margin-top: calc($standard-padding/2);
  padding-bottom: calc($standard-padding/3);
  &.error {
    color: $red;
    font-size: $base-font-size;
    text-transform: none;
    margin-top: calc($standard-padding/3);
    font-weight: $light-font-weight;
  }
}

.required {
  color: $red;
  font-weight: $bold-font-weight;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $lighter-gray;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 & Edge*/
  color: $lighter-gray;
}
