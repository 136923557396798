.stacked-status-changed {
  z-index: 1300;
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  gap: 20px;
  height: auto;
  width: max-content;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in-out;

  .status-changed {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding: 0 30px;
    color: black;
    background-color: #cae7ca;
    font-size: 16px;
    box-shadow: 0 20px 25px -5px rgba(34, 34, 34, 0.2),
      0px 10px 10px -5px rgba(34, 34, 34, 0.04);
    cursor: default;
  }

  .status-changed.error-status {
    background-color: #a13a12;
    color: #fff;
    height: 100px;
  }
}
