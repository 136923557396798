.ui-autocomplete {
  border-radius: 10px;
  z-index: 100 !important;
  font-family: "Proxima Nova", sans-serif !important;
}

.ui-menu-item {
  padding: 10px;
}

.ui-menu-item .ui-menu-item-wrapper.ui-state-active {
  background: transparent;
  border: none;
  color: #E86431;
}

.search-term {
  padding-right: 42px !important;
}
