.youtube-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10010;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.4s;
  
  .youtube-modal-container {
    width: 100%;
    max-width: 90%;
    aspect-ratio: 16 / 9;
    transform: translateY(0vh);
    transition: all 0.4s ease-out;
    @media(min-width: 720px){
      max-width: min(1400px, 90vh * 16 / 9, 80vw);
    }
    .youtube-modal {
      display: block;
      height: 100%;
      width: 100%;
      border: none;
    }
  }
  .hide {
    transform: translateY(105vh);
    transition: all 0.3s ease-out;
  }
}
.hide {
  background-color: rgba(0, 0, 0, 0.0);
  transition: background-color 0.3s;
}
