.carousel-container{
  position: relative;
  margin-top: 5px;
  h3{
    margin-left: 4px;
    margin-bottom: 15px;
    color: #FCED17;
    font-family: "Proxima Nova Bold Italic", sans-serif !important;
    font-size: 40px;
    font-weight: 900;
    font-style: italic;
    z-index: 100;
    position: relative;
    cursor: pointer;
    margin-top: 0;
    .icon{
      position: relative;
      top: 0px;
      margin-left: 25px;
      font-size: 25px;
      color: #FCED17;
    }
  }
  .left-scroll-section,
  .right-scroll-section{
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    height: calc(100% - 20px);
    width: 125px;
    z-index: 10000;
  }
  .left-scroll-section{
    justify-content: left;
    left: 0;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 125, 127, 0.75));
  }
  .right-scroll-section{
    justify-content: right;
    right: 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 125, 127, 0.75));
  }
  .left-scroll-button,
  .right-scroll-button{
    margin-top: 48px;
    color: #FCED17;
    font-size: 40px;
    font-weight: 600;
    cursor: pointer;
  }
  .left-scroll-button{
    left: 20px;
  }
  .right-scroll-button{
    right: 20px;
  }
  @media(max-width: 780px){
    .left-scroll-section,
    .right-scroll-section{
      display: none;
    }
  }
  .cards{
    display: flex;
    gap: 20px;
    position: relative;
    padding: 4px 4px 20px;
    white-space: nowrap;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar{
      height: 0;
      width: 0;
    }
  }
  .job-cards{
    display: flex;
    gap: 20px;
    padding: 4px 4px 20px;
    white-space: nowrap;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar{
      height: 0;
      width: 0;
    }
  }
}
