.job-details-mobile {
  background-color: white;
}

.job-details{
  overflow-y: auto;
  padding: 25px 60px;
  width: 100%;
  height: min-content;
  @media(max-width: 1100px){
    padding: 25px 30px;
  }
  @media(max-width: 960px){
    padding: 25px 20px;
  }
  .back-icon{
    color: #E86431;
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px;
  }
  .back-to-jobs-button{
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    color: black;
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 16px;
    padding: 0;
    &:hover{
      color: black;
    }
  }
  .title{
    font-size: 34px;
    font-weight: 700;
    line-height: 36px;
  }
  .company{
    margin-top: 2px;
    color: #E86431;
    font-size: 20px;
    font-family: "Proxima Nova Bold", sans-serif;
  }
  .level{
    margin-top: 2px;
    color: #007D7F;
    font-size: 14px;
  }
  .salary-range{
    margin-top: 15px;
    color: black;
    font-size: 20px;
    line-height: 24px;
  }
  .salary-time-frame{
    color: black;
    font-size: 14px;
    line-height: 16px;
  }
  .location{
    margin-top: 15px;
    color: black;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    .location-icon {
      font-size: 18px;
      margin-right: 6px;
    }
  }
  .tags{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    column-gap: 3px;
    margin-top: 15px;
    line-height: 20px;
    .tag{
      color: black;
      font-size: 16px;
    }
  }
  .buttons-container{
    display: flex;
    margin-top: 15px;
    @media(max-width: 720px){
      display: block;
    }
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 97px;
      margin-right: 15px;
      color: white;
      background-color: #E86431;
      font-size: 14pt;
      font-weight: 700;
      &:hover{
        background-color: #A13A12;
      }
      @media(max-width: 720px){
        width: 100%;
      }
    }
    button{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 99px;
      margin: 0;
      padding: 0;
      color: #E86431;
      background-color: white;
      border: 2px solid #E86431;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0;
      &:hover{
        color: #A13A12;
        border-color: #A13A12;
      }
      &.already-saved{
        width: 109px;
      }
      &.copy-link-button{
        display: none;
      }
      @media(max-width: 720px){
        width: 100% !important;
        margin-top: 10px;
        &.copy-link-button{
          display: block;
          .copy-link{
            display: inline-block;
            top: 5px;
          }
        }
      }
      .favorite-icon, .copy-link{
        position: relative;
        top: -2px;
        margin-right: 10px;
      }
    }
    .mobile-copy-link-container{
      @media(max-width: 720px){
        display: none;
      }
    }
    .copy-link{
      position: relative;
      top: 12px;
      margin-left: 15px;
      color: #E86431;
      font-size: 21px;
      cursor: pointer;
      @media(max-width: 720px){
        margin-left: 0;
        text-align: center;
      }
    }
  }
  .gray-line{
    height: 2px;
    width: 100px;
    margin-top: 15px;
    background-color: #EBEBEB;
    @media(max-width: 720px){
      display: none;
    }
  }
  .about-us-container, .what-you-will-do-container, .benefits-container, .hiring-process-container{
    margin-top: 25px;
    color: black;
    h3{
      font-size: 20px;
      @media(max-width: 720px){
        margin-top: 35px;
        font-size: 24px;
      }
    }
    .about-us, .what-you-will-do, .hiring-process{
      margin-top: 25px;
      padding: 0;
      font-size: 14px;
      background-color: white;
      line-height: 16px;
      white-space: pre-line;
      @media(max-width: 720px){
        margin-top: 15px;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .benefits{
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin-top: 25px;
      background-color: white;
      .benefit{
        float: none;
        width: auto;
        margin-right: 20px;
        padding: 0;
        color: #007D7F;
        font-size: 14px;
        text-transform: capitalize;
        padding: 8px;
        display: flex;
        align-items: center;
        .benefit-icon{
          width: 20px;
          height: 20px;
          margin-right: 8px;
          display: flex;
          :first-child {
            width: 20px;
            height: 20px;
            color: #007D7F;
            :not([fill=none]) {
              fill: #007D7F;
            }
          }
        }
      }
    }
    .hiring-process-item {
      font-family: "Proxima Nova", sans-serif !important;
      font-weight: 400;
      font-size: 14px;
      &__step {
        font-weight: bold;
      }
    }
    .hiring-process-button {
      margin-top: 16px;
      padding: 16px 24px;
    }
  }
}
