.overlay {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	z-index: 100000;
}

.overlay__inner {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}

.overlay__content {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.Modal {
	background-color: white;
	z-index: 250;
	width: 90vw;
	max-width: 950px;
	&__header {
		height: 64px;
		display: flex;
		justify-content: flex-end;
		&__title {
			position: absolute;
			top: 32px;
			text-align: center;
			width: 100%;
		}
		&__closeButton {
			width: 14px;
			height: 14px;
			margin: 16px 16px 0 0;
			cursor: pointer;
		}
	}
	&__body {
		min-height: 86px;
		padding: 32px;
	}
}
