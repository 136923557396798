.favorite{
  .status-changed{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 80px;
    height: 80px;
    max-width: 90%;
    padding: 0 30px;
    color: black;
    background-color: #CAE7CA;
    font-size: 16px;
    box-shadow: 0 20px 25px -5px rgba(34, 34, 34, 0.2), 0px 10px 10px -5px rgba(34, 34, 34, 0.04);
    cursor: default;
    z-index: 1300;
    .error-status {
      background-color: #A13A12;
      color: #fff;
      height: 100px;
    }
  }
}