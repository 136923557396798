@use "sass:math";

@import "./base/_variables.scss";
@import './_grid-settings.scss';
@import './neat/neat';

@-ms-viewport {
  width: extend-to-zoom;
}

html, body {
  font-family: $base-font-family;
  height: 100%;
}

//FROALA STYLE CONFLICT FIXES

img,
picture {
  margin: 0;
  max-width: 100%;
  &.fr-dib {
    margin: calc($standard-padding/6) auto;
    display: block;
    float: none;
    vertical-align: top;
  }
  &.fr-fil {
    margin-left: 0;
  }
  &.fr-fir {
    margin-right: 0;
  }
}
.fr-video {
  display: block;
  padding: $standard-padding 0;
  margin: 0;
  max-width: 100%;

  iframe {
    max-width: 100%;
  }
}

.fr-emoticon {
  width: calc($standard-padding/1.2);
  height: calc($standard-padding/1.2);
  background-size: cover;
  display: inline-block;
}

.ui-autocomplete {
  background-color: $white;
  position: absolute;
}
.ui-menu {
  width: $standard-padding * 10 !important;
  border: calc($standard-padding/10) solid $blackish;
  border-top: 0px;
  .ui-menu-item {
    padding: calc($standard-padding/2);
    cursor: pointer;
    width: 100%;
    display: inline-block;
  }
}

.ui-state-focus {
  color: #ffffff;
  text-decoration: none;
  background-color: $teal;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  background-image: none;
}

//link-footer-font-sizea
a {
  &.see-more {
    display: block;
    text-align: center;
    color: $dark-gray;
    padding-top: calc($standard-padding/2);
    &::after {
      content: "»";
      color: $teal;
      margin-left: calc($standard-padding/3);
    }
    &:hover {
      color: $teal;
      &::after {
        color: $teal;
      }
    }
  }
}

// Footer

.footer {
  background-color: $navy-bluish;
  position: relative;
  // @include texture($navy-bluish, $navy-bluish, $navy-bluish, 55, 1);
  @include outer-container(100%);
  @include pad($standard-padding * 2 $standard-padding);
  a {
    cursor: pointer;
  }
  .footer-link-area {
    color: $white;
    cursor: pointer;
    display: block;
    margin: 0 auto !important;
    @include span-columns(2.4 of 12);
    @include media($large-screen-down) {
      float: none !important;
      @include span-columns(12 of 12);
      padding: 15px 0;
    }
    &.open {
      .list {
        display: block;
      }
      .plus {
        -ms-transform: rotate(45deg); /* IE 9 */
        -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
        transform: rotate(45deg);
      }
    }
    a {
      color: $white;
      opacity: 0.5;
      &:hover {
        color: $teal;
        opacity: 1;
      }
    }
    p {
      color: $whitish;
      &:hover {
        color: $teal;
      }
    }
    .plus {
      display: none;
      max-width: 15px;
      float: right;
      position: relative;
      top: $standard-padding * -1;
      @include media($large-screen-down) {
        display: block;
      }
    }
    h2 {
      font-size: 18px;
      padding-bottom: 10px;
      text-transform: none;
      font-weight: 600;
      letter-spacing: 2px;
      cursor: pointer;
      @include media($large-screen-down) {
        cursor: pointer;
      }
    }
    .list {
      display: block;
      @include media($large-screen-down) {
        display: none;
      }
      a > p {
        color: $whitish;
      }
    }
  }
}

.lower-footer {
  background-color: $blackish;
  color: $white;
  position: relative;
  // @include texture($blackish, $blackish, $blackish, 55, 1);
  @include pad($standard-padding * 2 $standard-padding);
  .copyright {
    font-size: 14px;
    a {
      color: $white;
      pointer-events: none;
    }
    p {
      color: $white;
    }
  }
  ul {
    display: block;
    float: right;
    position: relative;
    top: -80px;
    @include media($large-screen-down) {
      float: none;
      margin: 0 auto;
      width: 208px;
      top: 0px;
      padding-top: calc($standard-padding/2);
    }
    &.social {
      @include media($large-screen-down) {
        margin: 0;
      }
    }
    li {
      display: inline;
      padding: math.div($standard-padding, 7);
    }
  }
}

// general
.capitalize {
  text-transform: capitalize;
}

// file uploader
#file-upload-area {
  a,
  img {
    cursor: pointer;
  }

  button.upload-file {
    color: $blackish-rgba;
    background: $white;
    border: 3px dashed $blackish-rgba;
    font-size: $base-font-size;
    height: $logo-size;
    max-width: $standard-padding * 10;
    padding: calc($standard-padding/2);
    text-transform: none;
    width: 100%;
  }
}

// word-wrapping
.break-it {
  word-break: break-word;
  word-wrap: break-word;
}
