.no-results-found {
  margin: 40px 0 125px 30px;
  @media (max-width: 780px) {
    margin: 25px 0 15px 2px;
  }
  .title {
    color: black;
    font-size: 34px;
    font-family: 'Proxima Nova Bold', sans-serif;
    line-height: 36px;
  }
  .description {
    margin-top: 40px;
    color: black;
    font-size: 16px;
    line-height: 20px;
    @media (max-width: 720px) {
      margin-top: 35px;
    }
    a {
      color: #e86431;
      text-decoration: none;
    }
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 180px;
    margin: 55px 0 0 0;
    padding: 0;
    color: white;
    background-color: #e86431;
    font-size: 14pt;
    font-family: 'Proxima Nova Bold', sans-serif;
    border: none;
    letter-spacing: 0;
    &:hover {
      background-color: #a13a12;
    }
    @media (max-width: 720px) {
      width: 100%;
      margin-top: 30px;
    }
  }
}
