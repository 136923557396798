.applaudher-form-banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;

  @media (max-width: 780px) {
    padding: 16px;
  }

  h1 {
    color: yellow;
    font-size: 72px;
    text-align: center;

    @media (max-width: 1024px) {
      font-size: 56px;
    }

    @media (max-width: 768px) {
      font-size: 42px;
    }
  }

  p {
    color: white;
  }
}

.applaudher-form-container {
  background: white;
  padding: 32px;
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 780px) {
    padding: 16px;
  }

  .applaudher-intro-text {
    margin-right: 32px;
    padding: 32px;
    background-color: #007A7C;
    color: white;
    max-width: 800px;

    .applaudher-link {
      color: yellow;
    }

    h1 {
      color: yellow;
      font-size: 48px;
    }

    @media (max-width: 1024px) {
      margin-right: 0;
      margin-bottom: 32px;

      h1 {
        font-size: 32px;
      }
    }

    @media (max-width: 780px) {
      padding: 16px;
    }
  }

  form {
    max-width: 415.5px;
  }

  label {
    font-family: 'Proxima Nova';
    font-size: 16px;
  }

  .recaptcha-wrapper {
    margin-top: 24px;
  }

  .recaptcha-error {
    color: #ED254E;
    font-size: 14px;
  }

  .submit-container {
    margin-top: 32px;

    button {
      background: #FB5714;
      color: white;
      font-size: 16px;
      padding: 12px 32px;
    }
  }
}