#MC-donation-form {
    width: calc(100% - 50px);
    margin: 20px auto;
    @media (max-width: 780px) {
      width: 100%;
      margin-top: 80px;
    }
}

.donate-banner {
  overflow-x: scroll;
}

.donate-banner-container {
  width: 640px;
  height: 310px;
  overflow: scroll;
}

.donate-banner-image {
  border-radius: 8px;
  padding: 2%;
}

.donate-banner-text {
  padding: 3.5% 3%;
  border: 1px solid black;
  border-radius: 8px;
  width: 100%;
  font-family: Proxima Nova;
}

.donate-banner-header {
  padding-bottom: 10px;
  font-size: 34px;
  line-height: 36px;
}

.donate-banner-description {
  color: black;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  word-spacing: 1.5px;
}

.donate-banner-button {
  background-color: #E86431;
  padding: 16px;
  border: 0px;
  color: #fff;
  font-family: "Proxima Nova Bold";
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: 0.5s;

  &:hover {
    background-color: #E86431;
    border: 3px solid #E86431;
  }
}
