.copy-link{
  cursor: pointer;
  position: relative;
  .link-copied{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 80px;
    height: 72px;
    width: 346px;
    color: black;
    background-color: #CAE7CA;
    font-size: 16px;
    box-shadow: 0 20px 25px -5px rgba(34, 34, 34, 0.2), 0px 10px 10px -5px rgba(34, 34, 34, 0.04);
    z-index: 150;
  }
  .favorite.share-social {
    color: #e86431;
  }

  &:hover {
    .favorite.share-social {
      color: #A13A12;
    }
  }
}
