div.resource {
  border-radius: 8px;
  width: 280px;
  transition: all 100ms ease-out;
  @media (min-width: 640px) {
    width: 332px;
    min-width: 332px;
  }
  &:hover, &:active, &.selected {
    outline: 4px solid #7FC3C4;
  }
  img {
    border-radius: 8px 8px 0 0;
  }
}

.resource{
  display: inline-block;
  background-color: white;
  box-shadow: 0 10px 15px -3px rgba(34, 34, 34, 0.2), 0 4px 6px -2px rgba(34, 34, 34, 0.05);
  z-index: 50;
  .top{
    height: 200px;
    .youtube-iframe-container{
      height: 200px;
      width: 100%;
      position: relative;
      iframe{
        height: 200px;
        width: 332px;
      }
      .youtube-iframe-overlay{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
    }
    img{
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }
  .bottom{
    position: relative;
    max-height: 88px;
    padding: 20px 18px 30px 18px;
    color: black;
    white-space: pre-wrap;
    .favorite{
      position: absolute;
      top: 30px;
      right: 20px;
      color: #E86431;
      background-color: transparent;
      font-size: 21px;
      cursor: pointer;
    }
    .name{
      height: 45px;
      padding-right: 25px;
      color: black;
      font-family: "Proxima Nova Bold", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.1;
      white-space: pre-wrap;
      overflow: hidden;
    }
    .tags{
      margin-top: 10px;
      color: black;
      font-size: 14px;
      font-weight: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }
  }
}
