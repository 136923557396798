.ApplyToJobModal {
	display: flex;
	flex-direction: column;
	align-items: center;
	&__body {
		display: flex;
		flex-direction: row;
		width: 100%;
		@media(max-width: 930px){
			flex-direction: column;
		}
	}
	&__recaptcha {
		margin-top: 16px;
		width: 100%;
		&--error {
			color: #ED254E;
			font-size: 14px;
			line-height: 16px;
		}
	}
	&__left {
		flex: 1;
		@media(max-width: 930px){
			padding: 0;
		}
	}
	&__right {
		flex: 1;
		padding-left: 32px;
		@media(max-width: 930px){
			padding: 0;
		}
	}
	&__button {
		margin-top: 32px;
		width: 30%;
		min-width: 164px;
	}
}