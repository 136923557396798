.applaudher-banner-container {
  display: flex;
  padding: 32px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }

  .hero-container {
    flex: 1 1 0%;
    position: relative;
    max-width: 400px;
    margin-right: 24px;

    .img-container {
      width: 2/3;
    }

    img {
      object-fit: cover;
      width: 100%;
      max-width: 360px;
    }

    &:before {
      --tw-bg-opacity: 1;
      background-color: rgb(252 237 23/var(--tw-bg-opacity));
      content: "";
      height: 75%;
      left: -2rem;
      position: absolute;
      top: -2rem;
      width: 100px;
      z-index: -1;
    }
  }

  .banner-text-container {
    flex: 1 1 0%;

    h1 {
      color: yellow;
      font-size: 72px;

      @media (max-width: 1024px) {
        font-size: 56px;
      }

      @media (max-width: 768px) {
        font-size: 42px;
      }
    }

    p {
      color: white;
      font-size: 24px;

      @media (max-width: 1024px) {
        font-size: 20px;
      }

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    button {
      background: #FB5714;
      color: white;
      font-size: 16px;
      padding: 12px 32px;
      margin-top: 48px;
    }
  }
}

.applaudher-content-container {
  background: white;
  padding: 32px 16px;
  
  .applaudhers {
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1fr));
    gap: 1rem;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2,minmax(0,1fr));
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(1,minmax(0,1fr));
    }
  }

  .applaudher-card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 320px;
    position: relative;

    .text-background {
      position: absolute;
      top: 0;
      left: 0;
      right: 32px;
      bottom: 32px;
      padding: 32px;
      overflow: hidden;
    }

    &:hover {
      .text-background {
        right: 0;
        bottom: 0;
      }
    }

    h1 {
      color: yellow;
      font-size: 40px;

      @media (max-width: 1200px) {
        font-size: 35px;
      }

    }

    h2 {
      color: white;
      font-size: 36px;
      padding-bottom: 30px;
    }

    p {
      color: white;
      font-size: 16px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      height: 100%;
      margin: 0;
    }

    .hover-description {
      --webkit-line-clamp: 5;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    gap: 8px;

    button {
      background:white;
      border: 1px solid #FB5714;
      color: #FB5714;
      padding: 8px 12px;
      font-size: 16px;
    }

    .active {
      background: #FB5714;
      color: white;
    }

    .ellipsis {
      margin: 0 4px;
      font-size: 16px;
    }
  }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}