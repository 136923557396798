@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/FontsFree-Net-ProximaNova-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova Bold";
  src: url("../fonts/FontsFree-Net-Proxima-Nova-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova Italic";
  src: url("../fonts/FontsFree-Net-ProximaNovaBlackItalic.ttf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova Bold Italic";
  src: url("../fonts/FontsFree-Net-ProximaNovaExtraboldItalic.ttf")
    format("truetype");
}

body {
  height: 100vh;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

a {
  text-decoration: none;
}
button {
  cursor: pointer;
}

.new-experience-container {
  background-image: url('../images/dashboard/green-grunge.webp');
  background-color: #007d7f;
  background-repeat: repeat-y;
  background-size: max(1920px, 100%) auto;
  z-index: -1000;
  @media (max-width: 820px) {
    background-image: none;
  }
  @media (min-width: 820px) {
    .navbar-container{
      display: none;
    }
    .new-experience .navbar-container{
      display: block;
    }
  } 
}

.dashboard-background {
  background-image: url('../images/dashboard/squiggly-line.png');
  background-repeat: no-repeat;
  background-position: left 30px top 10px;
  z-index: 20;
  background-size: auto;
}

.new-experience {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 20px 30px 20px 30px;
  font-family: "Proxima Nova", sans-serif !important;
  min-height: 100vh;
  .loader{
    display: flex;
    justify-content: center;
    margin:auto
  }
  @media (min-width: 781px) {
    &:not(.logged-in) {
      .navbar-container {
        display: none;
      }
      > div:nth-child(2) {
        width: 100% !important;
      }
    }
  }
  @media (max-width: 780px) {
    padding: 10px 15px 70px 15px;
    background-position: left 0 top 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Proxima Nova Bold", sans-serif;
  }
  ion-icon {
    pointer-events: none;
  }
  input.error {
    background-color: #ebebeb;
    border-color: #ed254e !important;
  }
  .error {
    color: #ed254e;
    font-size: 14px;
    line-height: 16px;
  }
  .tab-text {
    font-family: "Proxima Nova", sans-serif !important;
    color: #007d7f;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    &:hover {
      color: #a13a12;
    }
    @media (max-width: 420px) {
      font-size: 20px;
    }
    .disable-text {
      opacity: 0.9;
      color: #ebebeb;
      cursor: default;
    }
  }
  .active-tab {
    font-weight: 700 !important;
    text-decoration-line: underline;
  }
  .status-changed {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 80px;
    height: 80px;
    width: 400px;
    max-width: 90%;
    padding: 0 30px;
    color: black;
    background-color: #cae7ca;
    font-size: 16px;
    box-shadow: 0 20px 25px -5px rgba(34, 34, 34, 0.2),
      0px 10px 10px -5px rgba(34, 34, 34, 0.04);
    cursor: default;
    z-index: 1300;
  }
  .error-status {
    background-color: #A13A12;
    color: #fff;
    height: 100px;
  }
}

.body1 {
  line-height: 1.4rem;
  font-size: 1.2rem;
  font-family: "Proxima Nova", sans-serif !important;
  color: #ffffff;
  margin: 25px 50px 0px 0px;
  font-weight: 200;
  @media (max-width: 860px) {
    line-height: 20px;
    font-size: 16px;
    margin-right: 0px;
  }
}

p{
  margin: 0;
}