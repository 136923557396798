@mixin fontStyles {
  font-family: Proxima Nova;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin: 4px 0 0 0;
  text-transform: capitalize;
}

.card {
  width: 100%;
  height: 132px;
  background-color: white;
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 10px 15px -3px rgba(34, 34, 34, 0.2),
    0px 4px 6px -2px rgba(34, 34, 34, 0.05);
  overflow: hidden;
  @media (max-width: 1280px) {
    height: fit-content;
    flex-direction: column;
  }
  &:active {
    outline: 4px solid #7FC3C4;
  }
  &:hover {
    outline: 4px solid #7FC3C4;
    .youtube-iframe-container {
      iframe {
        transform: scale(1.2, 1.2);
      }
    }

    .resource-img {
      transform: scale(1.2, 1.2);
    }
  }
}
.resource-img-link {
  position: relative;
  overflow: hidden;
  height: 132px;
  width: auto;
  aspect-ratio: 16/9;
  @media (max-width: 1280px) {
    height: 180px;
  }
}
.resource-img {
  object-fit: cover;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
  height: 132px;
  width: max(100%, 132px);
  transition: transform 200ms ease-out;
  border-radius: 8px 0 0 8px;
  @media (max-width: 1280px) {
    border-radius: 8px 8px 0 0;
    height: 100%;
    max-width: 100%;
  }
  &-skeleton {
    max-width: 40%;
    @media (max-width: 1280px) {
      max-width: 100%;
    }
  }
}
.resource-title {
  font-family: Proxima Nova;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  margin: 0;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.resource-tag {
  @include fontStyles();
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.resource-level {
  @include fontStyles();
  color: #007d7f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.resource-format {
  @include fontStyles();
  color: #818181;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.resource-content {
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1280px) {
    width: 100%;
    height: 152px;
  }
}
.content-wrapper {
  width: 75%;
  cursor: pointer;
  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.resource-right {
  width: auto;
  display: grid;
  align-items: center;
}
.star {
  width: 22px;
  height: 22px;
  background-color: blue;
}
.resource-icon {
  all: unset;
  width: 40px;
  height: 40px;
  display: grid;
  align-items: center;
  :first-child {
    margin: auto;
  }
}
.resource-icons {
  display: flex;
  justify-content: center;
  gap: 8px;
  @media (max-width: 1280px) {
    height: 100%;
    flex-direction: column;
    align-items: center;
  }
}
.resource-icons-skeleton {
  display: flex;
  gap: 8px;
  margin-left: 8px;
  width: 90px;
}
.favorite {
  color: #e86431;
  background-color: transparent;
  font-size: 21px;
  cursor: pointer;
  transition: color 300ms ease-in-out;
  &:hover {
    color: #A13A12;
  }
}
.youtube-iframe-container {
  position: relative;
  overflow: hidden;
  aspect-ratio: 16/9;
  border-radius: 8px 0 0 8px;
  max-height: 180px;
  min-width: 155px;
  @media (max-width: 1280px) {
    border-radius: 8px 8px 0 0;
  }
  iframe {
    height: 100%;
    width: 100%;
    transition: transform 200ms ease-out;
    
    @media (max-width: 650px) {
      height: 200px;
    }
  }
}
.youtube-iframe-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
