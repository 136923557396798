#onboarding-page,
#onboarding-complete-page {
	.image-container {
		@media (max-width: 860px) {
			display: none !important;
		}
	}

	.onboarding-container {
		min-height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		@media screen and (max-width: 860px) {
			margin-top: 40px;
		}
	}

	.content-container {
		margin-top: 86px;
		max-width: 1200px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@media (max-width: 860px) {
			width: 90%;
			margin-top: 0;
		}
	}

	.flexrow {
		display: flex;
		justify-content: center;
		@media (max-width: 860px) {
			flex-direction: column !important;
		}
	}

	.hero-section {
		@media (min-width: 860px) {
			min-height: 600px;
		}
	}

	.image-column {
		width: 50%;
		display: flex;
		justify-content: center;
		padding-left: 40px;
		@media (max-width: 860px) {
			width: 100%;
			margin: 20px 0px 0px 0px;
		}
	}

	.left-image {
		@media (max-width: 860px) {
			height: 200px !important;
			padding: 0px !important;
		}
	}

	.image {
		width: 80%;
		height: auto;

		@media (min-width: 860px) {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}

	.hide-on-mobile {
		@media (max-width: 860px) {
			display: none;
		}
	}

	.hide-on-desktop {
		display: none;
		@media (max-width: 860px) {
			display: block;
		}
	}

	.content-column {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@media (max-width: 860px) {
			width: 100%;
			margin-top: 30px;
		}
		&__buttons {
			display: flex;
		}
	}

	.link-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-top: 50px;
		justify-self: flex-end;
		@media (max-width: 860px) {
			flex-wrap: wrap;
			justify-content: space-around;
			padding: 0px 20px;
		}
	}

	.connect-footer {
		justify-self: flex-end;
		margin-bottom: 42px;
		@media (max-width: 860px) {
			margin-bottom: 0px;
		}

		&__links {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-top: 24px;
			@media (max-width: 860px) {
				margin-top: 32px;
				flex-direction: column;
				display: grid;
				grid-template-columns: auto auto;
				grid-column-gap: 24px;
				grid-row-gap: 12px;
			}
		}
		&__textContainer {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 24px;
			@media (max-width: 860px) {
				margin-top: 16px;
			}
			.text {
				line-height: 1rem;
				font-size: 0.9rem;
				font-family: "Proxima Nova", sans-serif !important;
				color: #ffffff;
				@media (max-width: 860px) {
					line-height: 16px;
					font-size: 14px;
				}
			}
		}
	}

	.header {
		line-height: 3.8rem;
		font-size: 3.2rem;
		font-weight: 900;
		font-family: "Proxima Nova Bold Italic", sans-serif !important;
		color: #fced17;
		@media (max-width: 860px) {
			line-height: 40px;
			font-size: 40px;
		}
	}

	.body1 {
		line-height: 1.4rem;
		font-size: 1.2rem;
		font-family: "Proxima Nova", sans-serif !important;
		color: #ffffff;
		margin: 25px 50px 0px 0px;
		@media (max-width: 860px) {
			line-height: 20px;
			font-size: 16px;
			margin-right: 0px;
		}
	}

	.raffle {
		line-height: 1.75rem;
		font-size: 1.5rem;
		font-family: "Proxima Nova Bold", sans-serif !important;
		color: #ffffff;
		margin-right: 50px;
		margin-top: 12px;
		@media (max-width: 860px) {
			line-height: 20px;
			font-size: 16px;
			margin-right: 0px;
		}
	}

	.onboarding-login-button {
		background-color: #e86431;
		color: #ffffff;
		height: 50px;
		width: 155px;
		border: 0px;
		margin: 16px 16px 0 0;
		padding: 16px 0px;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		line-height: 1rem;
		font-size: 14pt;
		font-weight: 900;
		font-family: "Proxima Nova Bold", sans-serif !important;
		white-space: nowrap;
		@media (max-width: 860px) {
			width: 100%;
		}

		&:hover {
			background-color: #a13a12;
			border: 0px;
		}
		&:active {
			border: 0px;
		}
		&:focus {
			border: 0px;
		}
	}
	a {
		line-height: 1rem;
		font-size: 1rem;
		font-family: "Proxima Nova", sans-serif !important;
		color: #ffffff;
		padding: 0px 12px;
		@media (max-width: 860px) {
			line-height: 16px;
			font-size: 14px;
			padding: 6px;
		}
	}
}