#new-dashboard{
  // background-image: url(asset_path("dashboard/squiggly-line.png"));
  background-repeat: no-repeat;
  background-position: left 30px top 10px;
  z-index: 20;
  .content-container{
    width: calc(100% - 110px);
    @media(max-width: 780px){
      width: 100%;
      margin-top: 80px;
    }
  }
}
