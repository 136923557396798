.Title {
	padding-bottom: 0;
	color: #007D7F;
	font-weight: 900;
	font-family: 'Proxima Nova Bold';
	font-size: 34px;
	line-height: 36px;
	margin: 0;
	@media (min-width: 1001px) {
		font-size: 40px;
		line-height: 60px;
	}
}

.h4 {
	padding-bottom: 0;
	color: #007D7F;
	font-weight: 700;
	font-family: 'Proxima Nova Bold';
	font-size: 24px;
	line-height: 24px;
	margin: 0;
	@media (min-width: 1001px) {
		font-size: 34px;
		line-height: 36px;
	}
}