.ScrollToTopButton {
  position: fixed;
  z-index: 1000;
  right: 36px;
  bottom: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  background-color: #EBEBEB;
  border-radius: 9999px;
  box-shadow: 0 10px 15px -3px rgba(34, 34, 34, 0.2), 0px 4px 6px -2px rgba(34, 34, 34, 0.05);
  cursor: pointer;
  transition: all 300ms ease-out;

  .icon{
    color: #007D7F;
  }
}
.visible {
  filter: opacity(1);
}
.invisible {
  filter: opacity(0);
}
