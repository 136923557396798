.resources-search{
    .top-row{
      display: flex;
      padding-top: 3px;
      @media(max-width: 720px){
        flex-wrap: wrap;
        border-top: none;
      }
      .input-container{
        position: relative;
        width: calc(100% - 64px);
        margin-right: 20px;
        @media(max-width: 720px){
          width: calc(100% - 60px);
          margin-bottom: 15px;
          margin-right: 0;
        }
        input{
          height: 48px;
          padding: 16px;
          width: 100%;
          background-color: white;
          border: 1px solid #000000;
          &::placeholder{
            color: #818181;
          }
          &:focus{
            border-color: #E86431;
          }
        }
        .icon{
          position: absolute;
          right: 18px;
          color: #818181;
        }
      }
      .filters-icon-container{
        display: none;
        position: relative;
        top: 7px;
        margin-left: auto;
        cursor: pointer;
        @media(max-width: 720px){
          display: block;
          margin-left: 8px;
        }
        .filters-icon{
          color: black;
          font-size: 34px;
        }
        .num-of-filters-set{
          display: none;
        }
        &.filters-set{
          .filters-icon{
            color: #007D7F;
          }
          .num-of-filters-set{
            display: block;
            position: absolute;
            top: -7px;
            right: -15px;
            padding: 2px 8px;
            color: white;
            background-color: #007D7F;
            font-size: 14px;
            border-radius: 9999px;
          }
        }
      }
      button{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 48px;
        width: 87px;
        margin-left: 0;
        margin-right: 0;
        padding: 16px;
        color: #E86431;
        background-color: white;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        border: 2px solid #E86431;
        @media(max-width: 720px){
          width: 100%;
        }
      }
    }
    .bottom-row{
      display: flex;
      margin-top: 15px;
      @media(max-width: 720px){
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        margin-top: 0;
        background-color: white;
        z-index: 100;
      }
      .close-icon-container{
        cursor: pointer;
        .close-icon{
          color: #E86431;
          font-size: 30px;
        }
      }
      .filters-label{
        margin-top: 30px;
        color: black;
        font-size: 34px;
        font-family: "Proxima Nova Bold", sans-serif;
      }
      .filter-container{
        width: calc(33% - 33px);
      }
      .mobile-filter-buttons{
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        height: 80px;
        width: 100%;
        background-color: white;
        box-shadow: 0 25px 50px -12px rgba(34, 34, 34, 0.35);
        z-index: 100;
        button{
          position: relative;
          top: 7px;
          height: 48px;
          width: calc(50% - 22.5px);
          margin-left: 15px;
          margin-right: 0;
          font-family: "Proxima Nova Bold", sans-serif;
          &.apply-button{
            color: white;
            background-color: #E86431;
            &:hover{
              background-color: #A13A12;
            }
          }
          &.reset-button{
            color: #E86431;
            background-color: white;
            border: 2px solid #E86431;
            &:hover{
              color: #A13A12;
              border-color: #A13A12;
            }
          }
        }
      }
      button{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 36px;
        width: 62px;
        padding-left: 0;
        padding-right: 0;
        color: #007D7F;
        background-color: transparent;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0;
        border: none;
      }
    }
  }