.logged-out-top-bar{
  display: flex;
  padding: 20px 18px 10px 28px;
  @media(max-width: 780px){
    display: none;
  }
  > a{
    position: relative;
    top: 5px;
    margin-right: 60px;
    @media(max-width: 1010px){
      display: none;
    }
  }
  .links{
    @media(max-width: 1010px){
      margin-left: 5px;
    }
    a{
      position: relative;
      top: 20px;
      margin-right: 40px;
      color: white;
      font-size: .875rem;
      line-height: 1.25rem;
      font-family: sans-serif;
      @media(max-width: 820px){
        margin-right: 25px;
      }
    }
  }
  .buttons{
    display: flex;
    margin-left: auto;
    button{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 204px;
      margin: 10px 6px;
      font-size: 14px;
      font-family: "Proxima Nova Bold", sans-serif;
      letter-spacing: 0;
      &.sign-up-button, &.donate-button{
        color: #007D7F;
        background-color: white;
        border: none;
        font-size: 1rem;
      }
      &.login-button{
        color: white;
        background-color: #E86431;
        border: none;
        @media(max-width: 1180px){
          display: none;
        }
        .person-icon{
          position: relative;
          top: -2px;
          margin-right: 10px;
          color: white;
        }
      }
    }
    .person-icon-container{
      display: none;
      @media(max-width: 1180px){
        display: block;
        margin-left: 8px;
        margin-right: 10px;
        cursor: pointer;
        .person-icon{
          position: relative;
          top: 18px;
          color: white;
          font-size: 30px;
        }
      }
    }
  }
}