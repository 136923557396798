.radio-label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 1rem;
    margin-left: 0.5rem;
    text-transform: none;

    &:hover {
        .custom-radio {
            box-shadow: 0 0 0 3px rgba(0, 125, 127, 0.4);
        }

        .native-radio {
            &:checked+.custom-radio {
                box-shadow: 0 0 0 6px rgba(0, 125, 127, 0.4);
            }
        }
    }

    .native-radio {
        display: none;

        &:checked+.custom-radio::after {
            transform: scale(1);
        }
    }

    .custom-radio {
        width: 28px;
        height: 28px;
        border: 1px solid #007D7F;
        border-radius: 50%;
        margin-right: 0.625rem;
        box-sizing: border-box;
        padding: 2px;
        position: relative;
        transition: all 150ms;

        &:after {
            position: absolute;
            content: "";
            top: -4px;
            left: -4px;
            bottom: -4px;
            right: -4px;
            display: block;
            border: 8px solid #007D7F;
            border-radius: 50%;
            transform: scale(0);
        }
    }
}